<template>
    <v-row class="align-self-start">
        <v-col>
            <template-constructor newTemplate @submit="createTemplate" />
            <v-overlay :value="loading">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-col>
    </v-row>
</template>

<script>
import router from '@/router';
import TemplateManagement from '@/api/TemplatesModule/TemplateManagement';
import TemplateConstructor from '@/components/TemplatesModule/TemplateConstructor/TemplateConstructor.vue';

export default {
    components: {
        TemplateConstructor,
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        async createTemplate(templateData) {
            this.loading = true;
            const templateManagement = new TemplateManagement();
            await templateManagement.createTemplate(templateData);
            this.loading = false;
            await router.push({ name: 'templates' });
        },
    },
};
</script>
