<template>
    <v-row justify="center" align="center" class="login-card">
        <img src="/images/logo.png" class="logo" />
        <v-col cols="12" sm="8" md="6">
            <v-card class="elevation-12">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Login</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <Errors />
                    <v-form>
                        <v-text-field
                            label="Email"
                            v-model="email"
                            prepend-icon="mdi-account"
                            type="text"
                            @keyup.enter="$refs.password.focus()"
                        ></v-text-field>
                        <v-text-field
                            ref="password"
                            label="Password"
                            v-model="password"
                            prepend-icon="mdi-lock"
                            type="password"
                            @keyup.enter="login"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="login()" color="accent">Login</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import router from '@/router';
import Errors from '@/components/Errors';

export default {
    data: function () {
        return {
            email: null,
            password: null,
        };
    },
    created() {
        if (this.isAuthenticated) {
            router.push({ name: 'templates' });
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated']),
    },
    methods: {
        login() {
            store.dispatch('login', { email: this.email, password: this.password });
        },
    },
    components: {
        Errors,
    },
};
</script>

<style scoped>
.logo {
    position: fixed;
    left: 0;
    top: 10vh;
    height: 80vh;
    transform: translateX(-35%);
    z-index: 0;
    display: block;
    opacity: 0.5;
}
</style>
