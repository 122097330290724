<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="companies">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Companies</v-toolbar-title>
                </v-toolbar>
                <v-data-table
                    height="500px"
                    :headers="companyHeaders"
                    :items="companies"
                    :search="search"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    mobile-breakpoint="0"
                >
                    <template v-slot:top>
                        <div class="searchbar ma-4">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                            <v-btn
                                style="
                                     {
                                        justify-self: end;
                                    }
                                "
                                class="mt-3 d-none d-sm-flex"
                                dark
                                @click="newCompanyDialog = true"
                                width="150px"
                                color="accent"
                                >New Company
                            </v-btn>
                            <v-btn
                                style="
                                     {
                                        justify-self: end;
                                    }
                                "
                                class="mt-3 d-flex d-sm-none"
                                dark
                                @click="newCompanyDialog = true"
                                width="50px"
                                color="accent"
                                >New
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="
                                selectedCompany = item;
                                nameEditingCompany = item.name;
                                editCompanyDialog = true;
                            "
                            >mdi-pencil
                        </v-icon>
                        <v-icon
                            small
                            @click="
                                selectedCompany = item;
                                confirmDeleteDialog = true;
                            "
                            >mdi-delete</v-icon
                        >
                    </template>
                </v-data-table>
                <v-dialog persistent v-model="newCompanyDialog" max-width="700px">
                    <v-card>
                        <v-toolbar dark flat color="primary">
                            <v-toolbar-title>New company</v-toolbar-title>
                        </v-toolbar>

                        <v-form
                            ref="newCompanyForm"
                            v-model="validNewCompany"
                            lazy-validation
                            class="ma-5"
                        >
                            <v-text-field
                                v-model="newCompany.name"
                                label="Company name"
                                :rules="nameRules"
                            ></v-text-field>
                            <v-divider></v-divider>
                            <p class="mt-6 mb-2">
                                Enter the account information for the admin user
                            </p>
                            <v-text-field
                                v-model="newCompany.adminFirstName"
                                label="First name"
                                :rules="nameRules"
                            ></v-text-field>
                            <v-text-field
                                v-model="newCompany.adminLastName"
                                label="Last name"
                                :rules="nameRules"
                            ></v-text-field>
                            <v-text-field
                                v-model="newCompany.adminEmail"
                                label="Email"
                                :rules="emailRules"
                            ></v-text-field>
                            <v-text-field
                                label="Password"
                                v-model="newCompany.adminPassword"
                                :rules="passwordRules"
                                type="password"
                            ></v-text-field>
                            <v-text-field
                                label="Confirm password"
                                :rules="passwordConfirmRules()"
                                v-model="newCompany.adminPasswordConfirm"
                                type="password"
                            ></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                @click="newCompanyDialog = false"
                                class="mr-5 mb-3"
                                >Cancel</v-btn
                            >
                            <v-btn color="accent" @click="createCompany()" class="mr-3 mb-3"
                                >Create</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="editCompanyDialog" max-width="700px">
                    <v-card>
                        <v-toolbar dark flat color="primary">
                            <v-toolbar-title>Edit company</v-toolbar-title>
                        </v-toolbar>
                        <v-form
                            ref="editCompanyForm"
                            v-model="validEditCompany"
                            lazy-validation
                            class="ma-5"
                        >
                            <v-text-field
                                v-model="nameEditingCompany"
                                label="Company name"
                                :rules="nameRules"
                            ></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                @click="editCompanyDialog = false"
                                class="mr-5 mb-3"
                                >Cancel</v-btn
                            >
                            <v-btn
                                color="accent"
                                @click="editCompany(selectedCompany)"
                                class="mr-3 mb-3"
                                >Save</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog persistent v-model="confirmDeleteDialog" max-width="600px">
                    <v-card>
                        <v-card-title
                            ><span class="headline"
                                >Delete "{{ selectedCompany.name }}"</span
                            ></v-card-title
                        >
                        <v-card-text class="pb-0">
                            Are you sure you want to delete this company? This will delete all data
                            from this company. Type the company name to confirm.
                            <v-text-field
                                class="mt-3"
                                v-model="companyDeleteNameInput"
                                label="Company name"
                                type="error"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                class="ma-4"
                                @click="
                                    confirmDeleteDialog = false;
                                    companyDeleteNameInput = null;
                                "
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                color="error"
                                :disabled="companyDeleteNameInput !== selectedCompany.name"
                                class="ma-4"
                                @click="deleteCompany(selectedCompany)"
                                >Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import CompanyManagement from '@/api/AccessManagement/CompanyManagement';

export default {
    data() {
        return {
            confirmDeleteDialog: false,
            companyDeleteNameInput: null,
            search: '',
            companyHeaders: [
                {
                    text: 'Company',
                    sortable: false,
                    align: 'start',
                    value: 'name',
                    width: '15%',
                },
                {
                    text: 'Admin email',
                    sortable: false,
                    align: 'start',
                    value: 'admin_email',
                    width: '30%',
                },
                {
                    text: 'Users',
                    sortable: false,
                    align: ' d-none d-sm-table-cell',
                    value: 'users',
                    width: '15%',
                },
                {
                    text: 'Templates',
                    sortable: false,
                    align: ' d-none d-sm-table-cell',
                    value: 'templates',
                    width: '15%',
                },
                {
                    text: 'Documents',
                    sortable: false,
                    align: ' d-none d-sm-table-cell',
                    value: 'documents',
                    width: '15%',
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: false,
                    align: ' d-none d-md-table-cell end',
                },
            ],
            companies: [],
            validNewCompany: false,
            newCompanyDialog: false,
            newCompany: {
                name: null,
                adminFirstName: null,
                adminLastName: null,
                adminEmail: null,
                adminPassword: null,
                adminPasswordConfirm: null,
            },
            editCompanyDialog: false,
            validEditCompany: false,
            selectedCompany: {
                name: null,
            },
            nameEditingCompany: null,
            uniqueEmail: true,

            nameRules: [
                (v) => !!v || 'This field is required',
                (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
            ],

            passwordRules: [
                (v) => !!v || 'This field is required',
                (v) => (v && v.length >= 8) || 'This field must be at least 8 characters',
                (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
            ],
        };
    },
    watch: {
        'newCompany.adminEmail': function () {
            this.uniqueEmail = true;
        },
    },
    methods: {
        async getAllCompanies() {
            const companyManagement = new CompanyManagement();
            this.companies = await companyManagement.getCompanies();
        },
        async createCompany() {
            await this.$refs.newCompanyForm.validate();
            if (this.validNewCompany) {
                const companyManagement = new CompanyManagement();
                const response = await companyManagement.createCompany(this.newCompany);
                if (response === false) {
                    this.uniqueEmail = false;
                    await this.$refs.newCompanyForm.validate();
                } else {
                    this.newCompanyDialog = false;
                    this.newCompany = {
                        name: null,
                        adminFirstName: null,
                        adminLastName: null,
                        adminEmail: null,
                        adminPassword: null,
                        adminPasswordConfirm: null,
                    };
                    this.$refs.newCompanyForm.reset();
                    await this.getAllCompanies();
                }
            }
        },
        async editCompany(company) {
            await this.$refs.editCompanyForm.validate();
            if (this.validEditCompany) {
                if (this.nameEditingCompany !== this.selectedCompany.name) {
                    const companyManagement = new CompanyManagement();
                    await companyManagement.editCompanyName(this.nameEditingCompany, company.id);
                    this.newCompanyDialog = false;
                    await this.getAllCompanies();
                }
                this.editCompanyDialog = false;
            }
        },
        async deleteCompany(company) {
            const companyManagement = new CompanyManagement();
            await companyManagement.deleteCompany(company.id);
            this.newCompanyDialog = false;
            await this.getAllCompanies();
            this.confirmDeleteDialog = false;
        },
        passwordConfirmRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) =>
                    (v && v === this.newCompany.adminPassword) ||
                    'This field must match the password field',
            ];
        },
    },
    computed: {
        ...mapGetters(['userCan']),
        emailRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
                (v) =>
                    (v &&
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            String(v).toLowerCase()
                        )) ||
                    'This must be a valid email address',
                this.uniqueEmail || 'A user with this email already exists',
            ];
        },
    },
    beforeMount() {
        this.getAllCompanies();
    },
};
</script>

<style>
.searchbar {
    display: grid;
    grid-template-columns: 9fr 1fr;
    grid-column-gap: 35px;
}
</style>
