<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="new-group-card elevation-5">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>New Role</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <Errors />
                    <v-form ref="form" v-model="valid">
                        <v-text-field
                            class="ml-4 mr-4"
                            v-model="newRoleName"
                            label="Role name"
                            :rules="roleRules"
                        ></v-text-field>
                    </v-form>
                    <v-data-table
                        height="500px"
                        :headers="permissionHeaders"
                        :items="permissionContent"
                        :search="searchRoles"
                        disable-pagination
                        hide-default-footer
                        fixed-header
                        dense
                        class="mt-4 ml-4 mr-4"
                        mobile-breakpoint="0"
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.permissionCategory }}</td>
                                <td>
                                    <v-checkbox
                                        color="black"
                                        :disabled="
                                            retrievePermissionCategory(
                                                disabledPermissions,
                                                item.permissionCategory
                                            ).create
                                        "
                                        v-model="item.create"
                                    ></v-checkbox>
                                </td>
                                <td>
                                    <v-checkbox
                                        color="black"
                                        :disabled="
                                            retrievePermissionCategory(
                                                disabledPermissions,
                                                item.permissionCategory
                                            ).read
                                        "
                                        v-model="item.read"
                                    ></v-checkbox>
                                </td>
                                <td>
                                    <v-checkbox
                                        color="black"
                                        :disabled="
                                            retrievePermissionCategory(
                                                disabledPermissions,
                                                item.permissionCategory
                                            ).update
                                        "
                                        v-model="item.update"
                                    ></v-checkbox>
                                </td>
                                <td>
                                    <v-checkbox
                                        color="black"
                                        :disabled="
                                            retrievePermissionCategory(
                                                disabledPermissions,
                                                item.permissionCategory
                                            ).delete
                                        "
                                        v-model="item.delete"
                                    ></v-checkbox>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :to="{ name: 'rolemanagement' }" class="mr-5 mb-3"
                        >Cancel</v-btn
                    >
                    <v-btn color="accent" @click="saveNewRole()" class="mr-3 mb-3">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapMutations } from 'vuex';
import router from '@/router';
import Errors from '@/components/Errors';
import RoleManagement from '@/api/AccessManagement/RoleManagement';

export default {
    data: () => ({
        valid: false,
        searchRoles: '',
        newRoleName: null,
        permissionHeaders: [
            { text: 'Permission', value: 'permissionCategory', sortable: false },
            { text: 'Create', value: 'create', sortable: false },
            { text: 'View', value: 'read', sortable: false },
            { text: 'Edit', value: 'update', sortable: false },
            { text: 'Delete', value: 'delete', sortable: false },
        ],
        permissionContent: [
            {
                permissionCategory: 'Templates',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Documents',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Users',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Groups',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Roles',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Contacts',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
        ],
        disabledPermissions: [
            {
                permissionCategory: 'Templates',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Documents',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Users',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Groups',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Roles',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Contacts',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
        ],
        roleNames: [],
    }),
    computed: {
        roleRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
                (v) => (v && !this.roleNames.includes(v.trim())) || 'This role name already exists',
            ];
        },
    },
    watch: {
        //Forces relations between permissions
        permissionContent: {
            handler(permissions) {
                const templates = this.retrievePermissionCategory(permissions, 'Templates');
                const documents = this.retrievePermissionCategory(permissions, 'Documents');
                const users = this.retrievePermissionCategory(permissions, 'Users');
                const groups = this.retrievePermissionCategory(permissions, 'Groups');
                const roles = this.retrievePermissionCategory(permissions, 'Roles');
                const contacts = this.retrievePermissionCategory(permissions, 'Contacts');

                const templates_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Templates'
                );
                const users_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Users'
                );
                const groups_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Groups'
                );
                const roles_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Roles'
                );
                const contacts_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Contacts'
                );

                if (!templates.create && !templates.update && !groups.create) {
                    users_disabled.read = false;
                }
                if (!templates.create && !templates.update && !users.update && !users.create) {
                    groups_disabled.read = false;
                }
                if (!users.update && !users.create) {
                    roles_disabled.read = false;
                }
                if (!documents.update && !documents.create) {
                    templates_disabled.read = false;
                    contacts_disabled.read = false;
                }

                //Cannot create/edit template when only Create/UpdateTemplate is enabled -> need ReadUsers and ReadGroups
                if (templates.create || templates.update) {
                    users.read = true;
                    groups.read = true;
                    users_disabled.read = true;
                    groups_disabled.read = true;
                }
                //Cannot edit or create document when only CreateDocuments UpdateDocuments is enabled -> need ReadTemplates and ReadContacts also
                if (documents.update || documents.create) {
                    templates.read = true;
                    templates_disabled.read = true;
                    contacts.read = true;
                    contacts_disabled.read = true;
                }
                //Cannot edit/create users when only UpdateUsers is enabled -> need ReadRoles and ReadGroups also
                if (users.update || users.create) {
                    groups.read = true;
                    roles.read = true;
                    groups_disabled.read = true;
                    roles_disabled.read = true;
                }
                //Cannot create group when only CreateGroup is enabled -> need ReadUsers also
                if (groups.create) {
                    users.read = true;
                    users_disabled.read = true;
                }
                if (contacts.create || contacts.update) {
                    users.read = true;
                    groups.read = true;
                    users_disabled.read = true;
                    groups_disabled.read = true;
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapMutations(['addError', 'resetErrors']),

        async saveNewRole() {
            this.resetErrors();
            await this.$refs.form.validate();
            if (!this.valid) return;
            if (this.getPermissions().length <= 0) {
                this.addError('Select at least one permission');
                return;
            }

            const roleManagement = new RoleManagement();
            await roleManagement.createRole({
                name: this.newRoleName.trim(),
                permissions: this.getPermissions(),
            });
            await router.push({ name: 'rolemanagement' });
        },

        getPermissions() {
            const permissions = [];
            for (const permission of this.permissionContent) {
                if (permission.create) {
                    permissions.push('Create' + permission.permissionCategory);
                }
                if (permission.read) {
                    permissions.push('Read' + permission.permissionCategory);
                }
                if (permission.update) {
                    permissions.push('Update' + permission.permissionCategory);
                }
                if (permission.delete) {
                    permissions.push('Delete' + permission.permissionCategory);
                }
            }
            return permissions;
        },

        retrievePermissionCategory(permissions, category) {
            for (const permission of permissions)
                if (permission.permissionCategory === category) return permission;
        },
    },
    components: {
        Errors,
    },
    async created() {
        //Retrieve the names of all roles
        const roleManagement = new RoleManagement();
        const allRoles = await roleManagement.getRoles();
        const names = [];
        for (const role of allRoles) names.push(role.name);
        this.roleNames = names;
    },
};
</script>
