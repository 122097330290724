<template>
    <v-row class="align-self-start">
        <v-col>
            <template-constructor
                v-if="isLoaded"
                :newTemplate="false"
                :oldTemplate="oldTemplate"
                @submit="editTemplate"
            />
            <v-overlay :value="loading">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-col>
    </v-row>
</template>

<script>
import router from '@/router';
import TemplateManagement from '@/api/TemplatesModule/TemplateManagement';
import TemplateConstructor from '@/components/TemplatesModule/TemplateConstructor/TemplateConstructor.vue';
import { retrieveAllTemplateElements } from '@/utils/TemplateDataProcessor';
import { find, isEqual } from 'lodash';

export default {
    components: {
        TemplateConstructor,
    },
    data() {
        return {
            oldTemplate: null,
            oldTemplateElements: [],
            isLoaded: false,
            loading: false,
        };
    },
    async created() {
        const templateManagement = new TemplateManagement();
        this.oldTemplate = await templateManagement.getSpecificTemplate(
            this.$route.params.templateid
        );
        this.oldTemplateElements = retrieveAllTemplateElements(this.oldTemplate.tree, true, false);
        this.isLoaded = true;
    },
    methods: {
        async editTemplate(templateData) {
            const templateManagement = new TemplateManagement();

            this.loading = true;

            templateData['big_update'] = this.isBigChangeComparedTo(templateData);

            await templateManagement.editTemplate(templateData, this.$route.params.templateid);

            this.loading = false;

            await router.push({ name: 'templates' });
        },

        isBigChangeComparedTo(templateData) {
            // If we use a different word file in the edited version of the template, it is a big change
            if (templateData.word_doc_id !== this.oldTemplate.word_doc_id) {
                return true;
            }
            // If we indicate that we have a person contact and/or a company contact in the edited version
            // of the template, it is a big change.
            if (templateData.has_person_contact !== this.oldTemplate.has_person_contact) {
                return true;
            }

            if (templateData.has_company_contact !== this.oldTemplate.has_company_contact) {
                return true;
            }

            // It is a big change if one or more optional sections and/or placeholders changed.
            for (const templateElement of templateData.elements) {
                const matchingOldTemplateElement = find(this.oldTemplateElements, {
                    name: templateElement.name,
                });

                if (!isEqual(templateElement, matchingOldTemplateElement)) {
                    return true;
                }
            }

            return false;
        },
    },
};
</script>
