import axios from 'axios';
import router from '../router';

let http = axios.create();
http.defaults.baseURL = '/api/';
http.$store = null;

http.interceptors.request.use(function (config) {
    if (http.$store != null && http.$store.getters.isAuthenticated) {
        config.headers.Authorization = 'Bearer ' + http.$store.getters.accessToken;
    }

    return config;
});

http.interceptors.response.use(
    (res) => res,
    (error) => {
        if (error.response.status === 401) {
            if (http.$store != null) {
                http.$store.commit('setAccessAndRefreshToken', {
                    access: false,
                    refresh: false,
                });
            }
            router.push({ name: 'login' });
            return;
        }
        return Promise.reject(error);
    }
);

export function setStoreForApiHandler(st) {
    http.$store = st;
}

export default http;
