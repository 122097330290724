var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"align-self-start"},[_c('v-col',[_c('v-card',{staticClass:"new-group-card elevation-5"},[_c('v-toolbar',{attrs:{"dark":"","flat":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Edit Role")])],1),_c('v-card-text',[_c('Errors'),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"ml-4 mr-4",attrs:{"label":"Role name","rules":_vm.roleRules},model:{value:(_vm.roleName),callback:function ($$v) {_vm.roleName=$$v},expression:"roleName"}})],1),_c('v-data-table',{staticClass:"mt-4 ml-4 mr-4",attrs:{"height":"500px","headers":_vm.permissionHeaders,"items":_vm.permissionContent,"search":_vm.searchRoles,"disable-pagination":"","hide-default-footer":"","fixed-header":"","dense":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.permissionCategory))]),_c('td',[_c('v-checkbox',{attrs:{"color":"black","disabled":_vm.retrievePermissionCategory(
                                            _vm.disabledPermissions,
                                            item.permissionCategory
                                        ).create},model:{value:(item.create),callback:function ($$v) {_vm.$set(item, "create", $$v)},expression:"item.create"}})],1),_c('td',[_c('v-checkbox',{attrs:{"color":"black","disabled":_vm.retrievePermissionCategory(
                                            _vm.disabledPermissions,
                                            item.permissionCategory
                                        ).read},model:{value:(item.read),callback:function ($$v) {_vm.$set(item, "read", $$v)},expression:"item.read"}})],1),_c('td',[_c('v-checkbox',{attrs:{"color":"black","disabled":_vm.retrievePermissionCategory(
                                            _vm.disabledPermissions,
                                            item.permissionCategory
                                        ).update},model:{value:(item.update),callback:function ($$v) {_vm.$set(item, "update", $$v)},expression:"item.update"}})],1),_c('td',[_c('v-checkbox',{attrs:{"color":"black","disabled":_vm.retrievePermissionCategory(
                                            _vm.disabledPermissions,
                                            item.permissionCategory
                                        ).delete},model:{value:(item.delete),callback:function ($$v) {_vm.$set(item, "delete", $$v)},expression:"item.delete"}})],1)])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-5 mb-3",attrs:{"color":"secondary","to":{ name: 'rolemanagement' }}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"mr-3 mb-3",attrs:{"color":"accent"},on:{"click":function($event){return _vm.saveEditedRole()}}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }