import ApiConsumer from '../ApiConsumer';

export default class TemplatingEngine extends ApiConsumer {
    async detect(file) {
        let fd = new FormData();
        fd.append('file', file);

        try {
            const response = await this.api.post('/file-upload/', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
}
