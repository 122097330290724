<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="new-group-card elevation-5" min-width="400px">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Edit Group</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-text-field
                            class="ml-4 mr-4"
                            v-model="newGroupName"
                            label="Group name"
                            :rules="groupRules"
                        ></v-text-field>
                    </v-form>
                    <v-data-table
                        height="350px"
                        :headers="usersHeaders"
                        :items="usersContent"
                        :search="searchUsers"
                        v-model="selectedUsers"
                        item-key="id"
                        disable-pagination
                        hide-default-footer
                        fixed-header
                        show-select
                        class="ml-4 mr-4"
                        mobile-breakpoint="0"
                    >
                        <template v-slot:top>
                            <v-text-field
                                v-model="searchUsers"
                                append-icon="mdi-magnify"
                                label="Search users"
                                class="mb-3"
                            ></v-text-field>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :to="{ name: 'groupmanagement' }" class="mr-5 mb-3" color="secondary"
                        >Cancel</v-btn
                    >
                    <v-btn color="accent" @click="saveEditedGroup()" class="mr-3 mb-3">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import router from '@/router';
import GroupManagement from '@/api/AccessManagement/GroupManagement';
import UserManagement from '@/api/AccessManagement/UserManagement';

export default {
    data: () => ({
        valid: false,
        newGroupName: null,
        searchUsers: '',
        usersHeaders: [
            { text: 'Name', value: 'name' },
            { text: 'Email', value: 'email', align: ' d-none d-sm-table-cell' },
            { text: 'Role', value: 'role_name', align: ' d-none d-sm-table-cell' },
        ],
        usersContent: [],
        selectedUsers: [],
        groupNames: [],
        originalGroupName: null,
    }),
    computed: {
        groupRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
                (v) =>
                    (v && (!this.groupNames.includes(v.trim()) || this.originalGroupName === v)) ||
                    'This group name already exists',
            ];
        },
    },
    methods: {
        async saveEditedGroup() {
            await this.$refs.form.validate();
            if (!this.valid) return;
            const groupid = this.$route.params.groupid;
            const groupManagement = new GroupManagement();
            await groupManagement.editedGroup(
                {
                    name: this.newGroupName.trim(),
                    users: this.selectedUsers.map((user) => user.id),
                },
                groupid
            );
            await router.push({ name: 'groupmanagement' });
        },
    },
    async beforeMount() {
        const groupid = this.$route.params.groupid;
        const groupManagement = new GroupManagement();

        //Retrieve the names of all roles
        const allGroups = await groupManagement.getGroups();
        const names = [];
        for (const group of allGroups) names.push(group.name);
        this.groupNames = names;

        const group = await groupManagement.getSpecificGroup(groupid);
        this.newGroupName = group.name;
        this.originalGroupName = group.name;
        this.selectedUsers = group.users;
        this.usersContent = await new UserManagement().getUsers();
    },
};
</script>

<style>
.new-group-card {
    margin: 40px;
}
</style>
