import NewGroup from '@/views/AccessManagement/Groups/NewGroup';
import EditGroup from '@/views/AccessManagement/Groups/EditGroup';

export default [
    {
        path: '/usermanagement/newgroup',
        name: 'newgroup',
        component: NewGroup,
        meta: {
            auth: true,
            capabilities: ['CreateGroups'],
        },
    },
    {
        path: '/usermanagement/:groupid/editgroup',
        name: 'editgroup',
        component: EditGroup,
        meta: {
            auth: true,
            capabilities: ['UpdateGroups'],
        },
    },
];
