import ApiConsumer from '../ApiConsumer';

export default class RoleManagement extends ApiConsumer {
    /**
     * Retrieves all roles
     */
    async getRoles() {
        try {
            const response = await this.api.get('/roles/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Retrieves a role by their role id
     */
    async getSpecificRole(roleId) {
        try {
            const response = await this.api.get('/roles/' + roleId + '/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Creates a specific role
     */
    async createRole(role) {
        try {
            const response = await this.api.post('/roles/', role);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Edits a specific role
     */
    async editRole(editedRole, id) {
        try {
            const response = await this.api.put('/roles/' + id + '/', editedRole);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Deletes a specific role
     */
    async deleteRole(id) {
        try {
            const response = await this.api.delete('/roles/' + id + '/');
            return response.data;
        } catch (error) {
            return false;
        }
    }
}
