import store from '../../store';

export default async (to, from, next) => {
    if (to.matched.some((route) => route.meta.auth)) {
        if (store.getters.isAuthenticated) {
            next();
        } else {
            await store.dispatch('checkAuth');
            if (store.getters.isAuthenticated) {
                next();
            } else {
                next({ name: 'login' });
            }
        }
    } else {
        next();
    }
};
