<template>
    <div>
        <v-container>
            <v-row class="mr-4">
                <v-col cols="4">
                    <v-card class="elevation-3">
                        <v-toolbar dense color="primary" dark flat>
                            <v-toolbar-title>Groups</v-toolbar-title>
                        </v-toolbar>
                        <v-data-table
                            height="300px"
                            :headers="groupsHeaders"
                            :items="groupsContent"
                            :search="searchGroups"
                            v-model="access.groups"
                            item-key="name"
                            disable-pagination
                            hide-default-footer
                            fixed-header
                            show-select
                            class="ml-4 mr-4"
                        >
                            <template v-slot:top>
                                <v-text-field
                                    v-model="searchGroups"
                                    append-icon="mdi-magnify"
                                    label="Search groups"
                                    single-line
                                    hide-details
                                    class="mb-4"
                                ></v-text-field>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <v-col cols="8">
                    <v-card class="elevation-3">
                        <v-toolbar dense color="primary" dark flat>
                            <v-toolbar-title>Individual users</v-toolbar-title>
                        </v-toolbar>
                        <v-data-table
                            height="300px"
                            :headers="usersHeaders"
                            :items="usersContent"
                            :search="searchUsers"
                            v-model="access.users"
                            item-key="id"
                            disable-pagination
                            hide-default-footer
                            fixed-header
                            show-select
                            class="ml-4 mr-4"
                        >
                            <template v-slot:top>
                                <v-text-field
                                    v-model="searchUsers"
                                    append-icon="mdi-magnify"
                                    label="Search users"
                                    single-line
                                    hide-details
                                    class="mb-4"
                                ></v-text-field>
                            </template>
                            <template v-slot:item="{ item, select, isSelected }">
                                <tr>
                                    <td>
                                        <v-simple-checkbox
                                            :value="isSelected"
                                            @input="select($event)"
                                        />
                                    </td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.email }}</td>
                                    <td>{{ item.role_name }}</td>
                                    <td>{{ item.groups.join(', ') }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import GroupManagement from '@/api/AccessManagement/GroupManagement';
import UserManagement from '@/api/AccessManagement/UserManagement';

export default {
    name: 'Step5Access',
    emits: ['cancel'],
    props: {
        value: {
            type: Object,
        },
    },
    async created() {
        const groupManagement = new GroupManagement();
        const userManagement = new UserManagement();
        this.groupsContent = await groupManagement.getGroups();
        this.usersContent = await userManagement.getUsers();
        this.access = this.value;
    },
    data: () => ({
        access: {
            users: [],
            groups: [],
        },
        searchUsers: '',
        usersHeaders: [
            { text: 'Name', value: 'name', sortable: false, width: '25%' },
            { text: 'Email', value: 'email', sortable: false, width: '25%' },
            { text: 'Role', value: 'role_name', sortable: false, width: '10%' },
            { text: 'Group(s)', value: 'groups', sortable: false, width: '40%' },
        ],
        usersContent: [],
        searchGroups: '',
        groupsHeaders: [
            { text: 'Group Name', sortable: false, value: 'name' },
            { text: 'Users', sortable: false, value: 'users' },
        ],
        groupsContent: [],
    }),
    watch: {
        access: function (value) {
            this.$emit('input', value);
        },
    },
};
</script>
