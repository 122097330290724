import store from '../../store';

export default async (to, from, next) => {
    if (
        to.matched.some(
            (route) => route.meta.capabilities !== undefined && route.meta.capabilities.length > 0
        )
    ) {
        if (!store.getters.isAuthenticated) {
            next({ name: 'login' });
        } else {
            const canAll = to.meta.capabilities.map((capability) => {
                return store.getters.userCan(capability);
            });
            if (canAll.includes(false)) {
                if (to.meta.capability_redirect !== undefined) {
                    next({ name: to.meta.capability_redirect });
                } else {
                    next({ name: 'home' });
                }
            } else {
                next();
            }
        }
    } else {
        next();
    }
};
