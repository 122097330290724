<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="template-registry elevation-5">
                <v-sheet
                    dark
                    class="pl-6 pb-4 pr-4 pt-2"
                    v-bind:class="{
                        top: userCan('CreateTemplates'),
                        'top-no-create': !userCan('CreateTemplates'),
                    }"
                    color="primary"
                >
                    <v-text-field
                        v-model="search"
                        label="Search templates"
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                    <v-btn
                        width="100%"
                        class="elevation-3 mt-4"
                        @click="newFolderDialog = true"
                        color="secondary"
                    >
                        <v-icon>mdi-folder-plus</v-icon>
                    </v-btn>
                    <v-btn
                        width="100%"
                        class="elevation-3 mt-4 d-none d-md-flex"
                        :to="{ name: 'newtemplate' }"
                        color="accent"
                        v-if="userCan('CreateTemplates')"
                        >New template
                    </v-btn>
                    <v-btn
                        width="100%"
                        class="elevation-3 mt-4 d-flex d-md-none"
                        :to="{ name: 'newtemplate' }"
                        color="accent"
                        v-if="userCan('CreateTemplates')"
                        >New
                    </v-btn>
                </v-sheet>
                <v-card-text>
                    <v-treeview
                        :items="templates"
                        :search="search"
                        :filter="filter"
                        :open.sync="open"
                        item-key="id"
                        hoverable
                        rounded
                        transition
                    >
                        <!--Prepend folder or template icon in front of row in tree view (depending on row type)-->
                        <template v-slot:prepend="{ item, open }">
                            <v-icon
                                @click="clickedNodeInTreeView(item)"
                                v-text="
                                    `mdi-${
                                        item.type === 'folder'
                                            ? open && item.children.length > 0
                                                ? 'folder-open'
                                                : 'folder'
                                            : 'file-cog'
                                    }`
                                "
                            ></v-icon>
                        </template>
                        <template v-slot:label="{ item }">
                            <a class="link" @click="clickedNodeInTreeView(item)">
                                <div width="100%">{{ item.name }}</div>
                            </a>
                        </template>
                        <!--After every row append a edit button-->
                        <template v-slot:append="{ item }">
                            <v-icon
                                v-if="item.type === 'folder'"
                                @click="
                                    currentlySelectedFolder = item;
                                    folderInformationDialog = true;
                                "
                                >mdi-pencil
                            </v-icon>
                            <v-btn
                                v-if="!(item.type === 'folder') && userCan('CreateDocuments')"
                                small
                                dark
                                elevation="0"
                                color="accent"
                                @click="createDocument(item)"
                                class="ml-4 d-none d-sm-flex"
                                >Create document
                            </v-btn>
                            <v-btn
                                v-if="!(item.type === 'folder') && userCan('CreateDocuments')"
                                small
                                dark
                                elevation="0"
                                color="accent"
                                @click="createDocument(item)"
                                class="ml-4 d-flex d-sm-none"
                                >Create
                            </v-btn>
                        </template>
                    </v-treeview>
                </v-card-text>
                <v-row justify="center">
                    <!--The dialog below opens when on the new folder button-->
                    <v-dialog v-model="newFolderDialog" max-width="600px">
                        <NewFolderDialog
                            @create-folder="createFolder"
                            v-bind:tree-structure="templates"
                        ></NewFolderDialog>
                    </v-dialog>
                    <!--The dialog below opens when pressing the move to folder button-->
                    <v-dialog v-model="elementMoveDialog" max-width="600px">
                        <MoveElement
                            @move-element="moveElement"
                            v-bind:tree-structure="templates"
                            v-bind:element="elementToMove"
                        ></MoveElement>
                    </v-dialog>
                    <!--The dialog below opens when the user clicks on a template in the treeview-->
                    <v-dialog v-model="templateInformationDialog" max-width="600px">
                        <TemplateInformation
                            v-if="currentlySelectedTemplateLoaded"
                            v-bind:template="currentlySelectedTemplate"
                            @delete="deleteTemplate"
                            @move-template="showMoveDialog"
                            @create-document="createDocument"
                        ></TemplateInformation>
                    </v-dialog>
                    <!--The dialog below opens when the user clicks on a folder in the treeview-->
                    <v-dialog v-model="folderInformationDialog" max-width="600px">
                        <FolderInformation
                            v-bind:folder="currentlySelectedFolder"
                            @delete-folder="deleteFolder"
                            @move-folder="showMoveDialog"
                            @update-folder="editFolder"
                        ></FolderInformation>
                    </v-dialog>
                    <!--The snackbar below is shown when the user tries to delete a folder which is not empty-->
                    <v-snackbar
                        color="error"
                        v-model="showDeleteNonEmptyFolderErrorMessage"
                        :timeout="3000"
                        bottom
                        transition="fade-transition"
                        >You can't delete a folder which is not empty
                    </v-snackbar>
                    <v-snackbar
                        color="error"
                        v-model="showDeleteNonEmptyFolderErrorMessage2"
                        :timeout="3000"
                        bottom
                        transition="fade-transition"
                        >This folder contains templates you have no access to, you can't delete it.
                    </v-snackbar>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';
import NewFolderDialog from '@/components/TemplatesModule/NewFolder.vue';
import MoveElement from '@/components/TemplatesModule/MoveElement';
import TemplateInformation from '@/components/TemplatesModule/TemplateInformation';
import FolderInformation from '@/components/TemplatesModule/FolderInformation';
import TemplateManagement from '@/api/TemplatesModule/TemplateManagement';

export default {
    components: {
        FolderInformation,
        TemplateInformation,
        MoveElement,
        NewFolderDialog,
    },
    data: () => ({
        open: [],
        folderIds: [],
        activeParentFolderToMoveTo: [null],
        activeParentFolderForNewFolder: [null],
        //Holds the search query entered in the top search bar.
        search: '',
        //Indicates whether or not the template information dialog should be shown.
        templateInformationDialog: false,
        //Indicates whether or not the template confirm deletion dialog should be shown.
        showDeleteTemplateConfirmationDialog: false,
        showDeleteNonEmptyFolderErrorMessage2: false,
        //Indicates whether or not the move to folder dialog should be shown.
        elementMoveDialog: false,
        //Indicates whether or not folder information dialog should be shown.
        folderInformationDialog: false,
        //Holds the text entered in the name textfield of the folder information dialog.
        folderInformationDialogName: null,
        //Indicates whether or not the new folder dialog should be shown.
        newFolderDialog: false,
        //v-model attribute of textfield upon creating a new folder.
        nameOfNewFolder: null,
        //Indicates whether we should show the delete non-empty folder error messages or not
        showDeleteNonEmptyFolderErrorMessage: false,
        //Holds the template object which is clicked on.
        currentlySelectedTemplate: null,
        currentlySelectedTemplateLoaded: false,
        //Holds the folder object which is clicked on.
        currentlySelectedFolder: null,
        //Holds the element to move, i.e. the template or folder for which we clicked on the move button.
        elementToMove: null,
        //Holds the tree hierarchy of templates and folders
        templates: [],
    }),
    computed: {
        ...mapGetters(['userCan']),
        /**
         * Filter is used to filter the tree hierarchy using the search data
         * entered by the user in the top search bar.
         */
        filter() {
            return (item, search, textKey) =>
                item[textKey].toLowerCase().indexOf(search.toLowerCase()) > -1;
        },
    },
    watch: {
        search: function (val) {
            if (val !== '') this.open = this.folderIds;
        },
    },
    async created() {
        await this.getFolderStructureRepresentation();
    },
    methods: {
        async createFolder(name, parentid) {
            await new TemplateManagement().createFolder(name, parentid);
            this.newFolderDialog = false;
            await this.getFolderStructureRepresentation();
        },

        async editFolder(folder) {
            const templateManagement = new TemplateManagement();
            await templateManagement.editFolder(folder.id, {
                name: folder.name,
            });
            this.folderInformationDialog = false;
            await this.getFolderStructureRepresentation();
        },

        showMoveDialog(element) {
            this.elementToMove = element;
            this.elementMoveDialog = true;
            this.templateInformationDialog = false;
            this.folderInformationDialog = false;
        },

        async moveElement(element, folderid) {
            const templateManagement = new TemplateManagement();
            if (element.type === 'folder') {
                await templateManagement.editFolder(element.id, { parent: folderid });
                this.activeParentFolderToMoveTo = [null];
            } else if (element.type === 'template') {
                await templateManagement.editTemplate(
                    { big_update: false, folder: folderid },
                    element.id
                );
                this.activeParentFolderToMoveTo = [null];
            }
            this.elementMoveDialog = false;
            await this.getFolderStructureRepresentation();
        },

        createDocument(template) {
            this.templateInformationDialog = false;
            router.push({
                name: 'createdocument',
                params: { templateid: template.id },
            });
        },

        async clickedNodeInTreeView(item) {
            //If we pressed on a template
            if (item.type === 'template') {
                //Show the template information dialog and set
                //the currently selected template to the selected template.
                this.templateInformationDialog = true;
                this.currentlySelectedTemplateLoaded = false;
                const templateManagement = new TemplateManagement();
                this.currentlySelectedTemplate =
                    await templateManagement.getSpecificTemplateMetaData(item.id);
                this.currentlySelectedTemplate['type'] = 'template';
                this.currentlySelectedTemplateLoaded = true;
            } else {
                if (this.open.indexOf(item.id) > -1) {
                    this.open.splice(this.open.indexOf(item.id), 1);
                } else {
                    this.open.push(item.id);
                }
            }
        },

        async deleteFolder(folder) {
            if (folder.children.length > 0) {
                this.showDeleteNonEmptyFolderErrorMessage = true;
            } else {
                var templateManagement = new TemplateManagement();
                const response = await templateManagement.deleteFolder(folder.id);
                if (response === false) {
                    this.showDeleteNonEmptyFolderErrorMessage2 = true;
                }
                await this.getFolderStructureRepresentation();
            }
            this.folderInformationDialog = false;
        },

        async deleteTemplate(template) {
            const templateManagement = new TemplateManagement();
            await templateManagement.delete(template.id);
            await this.getFolderStructureRepresentation();
            this.templateInformationDialog = false;
        },

        async getFolderStructureRepresentation() {
            const templateManagement = new TemplateManagement();
            const folderStructure = await templateManagement.getTemplatesWithFolderStructure();
            let folderStructureRepresentation = [];
            folderStructureRepresentation = this.createFolderStructureRepresentation(
                folderStructure.folders,
                folderStructure.templates
            );
            this.templates = folderStructureRepresentation;
            this.folderIds = this.getAllFolderIds(this.templates);
        },

        getAllFolderIds(root) {
            let folderIds = [];
            for (const element of root) {
                if (element.type === 'folder') {
                    folderIds.push(element.id);
                    folderIds = folderIds.concat(this.getAllFolderIds(element.children));
                }
            }
            return folderIds;
        },

        findParent(nodeOfWhichParentShouldBeFound) {
            for (let i = 0; i < this.templates.length; i++) {
                const found = this.recursiveFindParent(
                    this.templates[i],
                    nodeOfWhichParentShouldBeFound
                );
                if (found != null) {
                    return found;
                }
            }
            return null;
        },

        recursiveFindParent(currentNode, nodeToFind) {
            if (currentNode.children === undefined) return null;
            if (currentNode.children.indexOf(nodeToFind) > -1) {
                return currentNode;
            } else {
                for (const child of currentNode.children) {
                    const found = this.recursiveFindParent(child, nodeToFind);
                    if (found != null) {
                        return currentNode;
                    }
                }
                return null;
            }
        },

        createFolderStructureRepresentation(folders, templates) {
            const folderStructureRepresentation = [];
            for (const folder of folders) {
                folderStructureRepresentation.push({
                    type: 'folder',
                    id: folder.id,
                    name: folder.name,
                    children: this.createFolderStructureRepresentation(
                        folder.folders,
                        folder.templates
                    ),
                });
            }
            for (const template of templates) {
                template['type'] = 'template';
                folderStructureRepresentation.push(template);
            }
            return folderStructureRepresentation;
        },
    },
};
</script>

<style scoped>
.link {
    color: inherit;
}

.top {
    display: grid;
    grid-template-columns: minmax(300px, auto) 75px minmax(75px, 150px);
    grid-template-rows: auto;
    grid-gap: 20px;
}

.top-no-create {
    display: grid;
    grid-template-columns: minmax(300px, auto) 75px;
    grid-template-rows: auto;
    grid-gap: 20px;
}
</style>
