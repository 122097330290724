import ApiConsumer from './ApiConsumer';

export default class Auth extends ApiConsumer {
    constructor(store) {
        super();
        this.store = store;
    }

    async refreshToken(refreshToken) {
        try {
            const payload = {
                refresh: refreshToken,
            };
            const response = await this.api.post('/token/refresh/', payload);
            this.store.commit('setAccessAndRefreshToken', {
                access: response.data.access,
                refresh: refreshToken,
            });
        } catch (e) {
            this.store.commit('setAccessAndRefreshToken', {
                access: false,
                refresh: false,
            });
        }
    }

    /**
     * Retrieves current logged in user.
     */
    async getCurrentUser() {
        try {
            const response = await this.api.get('/auth/');
            return response.data.user;
        } catch (error) {
            return false;
        }
    }

    /**
     * Retrieves current logged in user.
     */
    async editCurrentUser(user) {
        let response;
        try {
            response = await this.api.patch('/auth/', user);
        } catch (error) {
            throw error.response.data.error;
        }
        return response.data.user;
    }

    async login(email, password) {
        try {
            const response = await this.api.post('/token/', { email, password });
            const access = response.data.access;
            const refresh = response.data.refresh;
            const user = response.data.user;

            sessionStorage.setItem('accessToken', access);
            sessionStorage.setItem('refreshToken', refresh);

            this.store.commit('setAccessAndRefreshToken', { access, refresh });
            this.store.commit('setLoggedInUser', user);

            return true;
        } catch (e) {
            return false;
        }
    }

    logout() {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        this.store.commit('setAccessAndRefreshToken', {
            access: false,
            refresh: false,
        });
    }
}
