<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">Template: {{ template.name }}</span>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item two-line class="pa-0">
                        <v-list-item-content>
                            <v-list-item-subtitle>Description</v-list-item-subtitle>
                            <div class="mt-1">{{ template.description }}</div>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line class="pa-0">
                        <v-list-item-content>
                            <v-list-item-subtitle>Created</v-list-item-subtitle>
                            <div class="mt-1">
                                by {{ template.creator_name }} on {{ createdAt }}
                            </div>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line class="pa-0">
                        <v-list-item-content>
                            <v-list-item-subtitle>Optional parts</v-list-item-subtitle>
                            <div class="mt-1">
                                {{ template.optional_sections.join(', ') }}
                            </div>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line class="pa-0">
                        <v-list-item-content>
                            <v-list-item-subtitle>Variable parts</v-list-item-subtitle>
                            <div class="mt-1">{{ template.placeholders.join(', ') }}</div>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    dark
                    color="error"
                    class="elevation-3 ma-4"
                    @click="confirmDialog = true"
                    v-if="userCan('DeleteTemplates')"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                    color="secondary"
                    class="elevation-3 ma-4"
                    @click="editTemplate(template)"
                    v-if="userCan('UpdateTemplates')"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    color="secondary"
                    class="elevation-3 ma-4"
                    @click="$emit('move-template', template)"
                    v-if="userCan('UpdateTemplates')"
                >
                    <v-icon>mdi-folder-move</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="accent"
                    class="elevation-3 ma-4"
                    @click="$emit('create-document', template)"
                    v-if="userCan('CreateTemplates')"
                    >Create document</v-btn
                >
            </v-card-actions>
        </v-card>
        <!--The dialog below opens when on the user has to confirm if he wants to delete a template-->
        <v-dialog v-model="confirmDialog" max-width="600px" v-if="userCan('DeleteTemplates')">
            <v-card>
                <v-card-title
                    ><span class="headline">Delete "{{ template.name }}"</span></v-card-title
                >
                <v-card-text class="pb-0">
                    Are you sure you want to delete this template? Documents created from this
                    template will not be editable anymore.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" class="elevation-3 ma-4" @click="confirmDialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn color="error" class="elevation-3 ma-4" @click="$emit('delete', template)"
                        >Delete</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import router from '@/router';
export default {
    props: {
        template: Object,
    },
    data: () => ({
        confirmDialog: false,
    }),
    methods: {
        editTemplate(template) {
            this.templateInformationDialog = false;
            router.push({
                name: 'edittemplate',
                params: { templateid: template.id },
            });
        },
    },
    computed: {
        ...mapGetters(['userCan']),
        createdAt() {
            const date = moment(this.template.created_at);
            return date.format('Do [of] MMMM YYYY [at] HH:mm');
        },
    },
};
</script>

<style scoped></style>
