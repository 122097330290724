<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="create-document-card elevation-5">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title
                        >{{ newDocument ? 'Create' : 'Edit' }} Document
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row v-if="loaded">
                            <v-col>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <div class="info-entry" v-if="documentTemplateKnown">
                                        <p class="mt-6">Template name</p>
                                        <div class="mt-6">{{ template.name }}</div>
                                    </div>
                                    <div class="info-entry">
                                        <p class="mt-6">Document name</p>
                                        <v-text-field
                                            :rules="labelRules"
                                            v-model="documentName"
                                            label="Enter document name"
                                        ></v-text-field>
                                    </div>
                                    <div class="info-entry">
                                        <p class="mt-6">Document code</p>
                                        <v-text-field
                                            :rules="labelRules"
                                            v-model="documentCode"
                                            label="Enter document code"
                                        ></v-text-field>
                                    </div>
                                    <template v-if="documentTemplateKnown">
                                        <div
                                            v-for="item of retrieveElementsToShow()"
                                            v-bind:key="item.id"
                                        >
                                            <div
                                                class="info-entry"
                                                v-if="item.type === 'optional_section'"
                                            >
                                                <p class="mt-4">{{ item.label }}</p>
                                                <v-checkbox
                                                    color="black"
                                                    v-model="getEntryById(item.id).value"
                                                ></v-checkbox>
                                            </div>
                                            <div class="info-entry" v-if="item.type === 'text'">
                                                <p class="mt-6">{{ item.label }}</p>
                                                <v-text-field
                                                    :rules="labelRules"
                                                    v-model="getEntryById(item.id).value"
                                                ></v-text-field>
                                            </div>
                                            <div class="info-entry" v-if="item.type === 'date'">
                                                <p class="mt-6">{{ item.label }}</p>
                                                <v-menu
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            v-model="getEntryById(item.id).value"
                                                            :rules="dateRules"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="getEntryById(item.id).value"
                                                        no-title
                                                        scrollable
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                            <div class="info-entry" v-if="item.type === 'number'">
                                                <p class="mt-6">{{ item.label }}</p>
                                                <v-text-field
                                                    :rules="validationRulesNumberPlaceHolder(item)"
                                                    v-model="getEntryById(item.id).value"
                                                    type="number"
                                                ></v-text-field>
                                            </div>
                                            <div
                                                class="info-entry"
                                                v-if="item.type === 'decimal_number'"
                                            >
                                                <p class="mt-6">{{ item.label }}</p>
                                                <v-text-field
                                                    :rules="
                                                        validationRulesDecimalNumberPlaceHolder(
                                                            item
                                                        )
                                                    "
                                                    v-model="getEntryById(item.id).value"
                                                    type="number"
                                                    step="0.1"
                                                ></v-text-field>
                                            </div>
                                            <div
                                                class="info-entry"
                                                v-if="item.type === 'choice_list'"
                                            >
                                                <p class="mt-4">{{ item.label }}</p>
                                                <v-select
                                                    :rules="validationChoiceListPlaceHolder(item)"
                                                    :items="item.meta_data.choices"
                                                    v-model="getEntryById(item.id).value"
                                                    label="Select option"
                                                ></v-select>
                                            </div>
                                            <div class="info-entry" v-if="item.type === 'currency'">
                                                <p class="mt-4">{{ item.label }}</p>
                                                <v-row dense>
                                                    <v-col cols="3">
                                                        <v-select
                                                            :rules="requiredRules"
                                                            :items="possibleCurrencies"
                                                            label="Currency"
                                                            v-model="
                                                                getEntryById(item.id).value
                                                                    .currency_format
                                                            "
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                            class="ml-3"
                                                            label="Enter amount"
                                                            :rules="
                                                                validationRulesDecimalNumberPlaceHolder(
                                                                    item
                                                                )
                                                            "
                                                            v-model="
                                                                getEntryById(item.id).value.amount
                                                            "
                                                            type="number"
                                                            step="0.01"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </template>
                                    <div v-else class="mt-6 mr-5">
                                        The template from which this document is created, is deleted
                                        or edited. The optional sections and placeholders in this
                                        document can not be edited anymore.
                                    </div>
                                </v-form>
                            </v-col>
                            <v-col class="ml-5">
                                <template
                                    v-if="documentTemplateKnown && template.has_person_contact"
                                >
                                    <v-card class="mb-4">
                                        <v-toolbar dense dark flat color="primary">
                                            <v-toolbar-title> Person Contact </v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text>
                                            Select a person contact to automatically fill the
                                            following placeholder(s):
                                            {{ personContactLabels.join(', ') }}
                                            <contacts-selector v-model="selectedPersonContact" />
                                        </v-card-text>
                                    </v-card>
                                </template>
                                <template
                                    v-if="documentTemplateKnown && template.has_company_contact"
                                >
                                    <v-card>
                                        <v-toolbar dense dark flat color="primary">
                                            <v-toolbar-title> Company Contact </v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text>
                                            Select a company contact to automatically fill the
                                            following placeholder(s):
                                            {{ companyContactLabels.join(', ') }}
                                            <contacts-selector
                                                v-model="selectedCompanyContact"
                                                load-companies
                                            />
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :to="{ name: 'documents' }" class="mt-5 mr-5 mb-3">
                        Cancel
                    </v-btn>
                    <v-btn color="accent" @click="submit" class="mt-5 mr-5 mb-3">
                        {{ newDocument ? 'Create' : 'Save' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <message-popup
                v-model="showSelectPersonPopup"
                title="Select person contact"
                message="This template requires a person contact. Please select one."
            />
            <message-popup
                v-model="showSelectCompanyPopup"
                title="Select company contact"
                message="This template requires a company contact. Please select one."
            />
        </v-col>
    </v-row>
</template>

<script>
import TemplateManagement from '@/api/TemplatesModule/TemplateManagement';
import DocumentManagement from '@/api/TemplatesModule/DocumentManagement';
import {
    retrieveAllTemplateElements,
    getPersonContactTemplateElementLabels,
    getCompanyContactTemplateElementLabels,
} from '@/utils/TemplateDataProcessor';
import {
    labelRules,
    dateRules,
    requiredRules,
    validationRulesDecimalNumberPlaceHolder,
    validationRulesNumberPlaceHolder,
    validationChoiceListPlaceHolder,
} from '@/utils/FieldValidations';
import { possibleCurrencies } from '@/utils/Constants';
import ContactsSelector from '@/components/Selectors/ContactsSelector';
import MessagePopup from '@/components/Popups/MessagePopup';
import { isEmpty } from 'lodash/lang';

export default {
    name: 'DocumentConstructor',
    emits: ['submit'],
    components: { MessagePopup, ContactsSelector },
    props: {
        // Only relevant when called from new document
        templateId: {
            type: String,
            default: null,
        },
        // Only relevant when called from edit document
        documentId: {
            type: String,
            default: null,
        },
        newDocument: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        // Always true when creating a document, on edit it is only true when
        // the associated template is known.
        documentTemplateKnown: false,

        // Template associated to the document.
        template: {},

        // The placeholders which are a field of a PersonContact.
        personContactLabels: [],
        // The placeholders which are a field of a CompanyContact.
        companyContactLabels: [],

        document: null,
        documentEntries: [],
        documentName: null,
        documentCode: null,

        selectedPersonContact: '',
        selectedCompanyContact: '',

        showSelectCompanyPopup: false,
        showSelectPersonPopup: false,

        possibleCurrencies: possibleCurrencies,

        valid: false,
        loaded: false,

        // Field validations
        labelRules: labelRules,
        dateRules: dateRules,
        requiredRules: requiredRules,
        validationRulesDecimalNumberPlaceHolder: validationRulesDecimalNumberPlaceHolder,
        validationRulesNumberPlaceHolder: validationRulesNumberPlaceHolder,
        validationChoiceListPlaceHolder: validationChoiceListPlaceHolder,
    }),
    async created() {
        const templateManagement = new TemplateManagement();

        if (!this.newDocument) {
            const documentManagement = new DocumentManagement();
            const document = await documentManagement.getSpecificDocument(this.documentId);
            this.document = document;
            this.documentName = document.name;
            this.documentCode = document.code;

            if (document.template === null) {
                this.loaded = true;
                return;
            }

            this.selectedPersonContact = this.document.person_contact;
            this.selectedCompanyContact = this.document.company_contact;

            this.template = await templateManagement.getSpecificTemplate(document.template.id);
        } else {
            this.template = await templateManagement.getSpecificTemplate(this.templateId);
        }

        this.documentTemplateKnown = true;

        const allElements = retrieveAllTemplateElements(this.template.tree, false, true);
        const usedIds = [];
        const allUniqueElements = [];

        if (this.newDocument) {
            for (const item of allElements) {
                if (usedIds.indexOf(item.id) === -1) {
                    allUniqueElements.push(item);
                    usedIds.push(item.id);
                }
            }
        } else {
            for (const item of allElements) {
                if (usedIds.indexOf(item.id) === -1) {
                    const finalItem = item;
                    if (finalItem.type === 'optional_section') {
                        finalItem.value = this.getOptionalSectionValueFromDocument(
                            this.document,
                            finalItem.id
                        );
                    } else {
                        const valueEntry = this.getPlaceholderFromDocument(
                            this.document,
                            finalItem.id
                        );
                        if (finalItem.type === 'currency') {
                            if (valueEntry !== undefined) {
                                // If the value is null, it means this currency placeholder is within an optional section
                                // and the optional section is set to false. In this case, we use the default currency format and
                                // an unknown amount as value.
                                if (valueEntry.value == null) {
                                    finalItem.value = {
                                        currency_format:
                                            valueEntry.placeholder.meta_data.currency_format,
                                        amount: null,
                                    };
                                }
                                // If the value is not null but can not be split by '_' in 2 elements, it means we are dealing
                                // with the old format of storing currencies in the database. Currently we store them as CURRENCY_AMOUNT, but
                                // previously we only stored AMOUNT. If we detect this, we just use the AMOUNT and use the default
                                // currency as CURRENCY value.
                                else if (valueEntry.value.split('_').length < 2) {
                                    finalItem.value = {
                                        currency_format:
                                            valueEntry.placeholder.meta_data.currency_format,
                                        amount: valueEntry.value.split('_')[0],
                                    };
                                } else {
                                    finalItem.value = {
                                        currency_format: valueEntry.value.split('_')[0],
                                        amount: valueEntry.value.split('_')[1],
                                    };
                                }
                            }
                        } else {
                            if (valueEntry !== undefined) {
                                finalItem.value = valueEntry.value;
                            }
                        }
                    }
                    allUniqueElements.push(finalItem);
                    usedIds.push(item.id);
                }
            }
        }

        this.documentEntries = allUniqueElements;

        if (this.template.has_person_contact) {
            this.personContactLabels = getPersonContactTemplateElementLabels(this.template.tree);
        }

        if (this.template.has_company_contact) {
            this.companyContactLabels = getCompanyContactTemplateElementLabels(this.template.tree);
        }

        this.loaded = true;
    },
    methods: {
        async submit() {
            await this.$refs.form.validate();
            if (!this.valid) return;

            if (!this.isPersonContactSelected()) {
                this.showSelectPersonPopup = true;
                return;
            }

            if (!this.isCompanyContactSelected()) {
                this.showSelectCompanyPopup = true;
                return;
            }

            const document = this.newDocument
                ? this.constructNewDocument()
                : this.constructEditedDocument();

            this.$emit('submit', document);
        },

        isPersonContactSelected() {
            return !(this.template.has_person_contact && isEmpty(this.selectedPersonContact));
        },

        isCompanyContactSelected() {
            return !(this.template.has_company_contact && isEmpty(this.selectedCompanyContact));
        },

        constructEditedDocument() {
            if (this.documentTemplateKnown) {
                const entries = this.retrieveElementsToShow();
                const placeholders = [];
                const optional_sections = [];

                for (const entry of entries) {
                    const element = this.getEntryById(entry.id);
                    if (element.type === 'optional_section') {
                        const entryId = this.getOptionalSectionFromDocument(
                            this.document,
                            element.id
                        ).id;
                        optional_sections.push({
                            id: entryId,
                            name: element.name,
                            value: element.value,
                        });
                    } else {
                        const value =
                            element.type === 'currency'
                                ? element.value.currency_format + '_' + element.value.amount
                                : element.value;
                        const entryId = this.getPlaceholderFromDocument(
                            this.document,
                            element.id
                        ).id;
                        placeholders.push({
                            id: entryId,
                            name: element.name,
                            value: value,
                        });
                    }
                }
                return {
                    name: this.documentName,
                    code: this.documentCode,
                    template: this.document.template.id,
                    placeholder_entries: placeholders,
                    optional_section_entries: optional_sections,
                    person_contact: this.selectedPersonContact,
                    company_contact: this.selectedCompanyContact,
                };
            } else {
                return {
                    name: this.documentName,
                    code: this.documentCode,
                };
            }
        },

        constructNewDocument() {
            const entries = this.documentEntries;
            const placeholders = [];
            const optional_sections = [];
            for (const entry of entries) {
                const element = this.getEntryById(entry.id);
                if (element.type === 'optional_section') {
                    optional_sections.push({
                        id: element.id,
                        name: element.name,
                        value: element.value,
                    });
                } else {
                    let value = null;
                    if (element.type === 'currency') {
                        if (element.value.amount !== null) {
                            value = element.value.currency_format + '_' + element.value.amount;
                        }
                    } else {
                        value = element.value;
                    }
                    placeholders.push({
                        id: element.id,
                        name: element.name,
                        value: value,
                    });
                }
            }
            return {
                name: this.documentName,
                code: this.documentCode,
                template: this.templateId,
                placeholders: placeholders,
                optional_sections: optional_sections,
                person_contact: this.selectedPersonContact,
                company_contact: this.selectedCompanyContact,
            };
        },

        retrieveElementsToShow() {
            const elementsToShow = this.retrieveElementsToShowRecursively(this.template.tree);
            const usedIds = [];
            const elementsToShowFinal = [];
            for (const item of elementsToShow) {
                if (usedIds.indexOf(item.label) === -1) {
                    elementsToShowFinal.push(item);
                    usedIds.push(item.label);
                }
            }
            return elementsToShowFinal;
        },

        retrieveElementsToShowRecursively(root) {
            let elementsToShow = [];
            if (root === undefined) return [];
            for (const element of root) {
                if (element.type === 'person_contact' || element.type === 'company_contact')
                    continue;
                elementsToShow.push(element);
                if (element.type === 'optional_section') {
                    if (this.getEntryById(element.id).value) {
                        elementsToShow = elementsToShow.concat(
                            this.retrieveElementsToShowRecursively(element.children)
                        );
                    }
                }
            }
            return elementsToShow;
        },

        getEntryById(id) {
            for (const entry of this.documentEntries) if (entry.id === id) return entry;
        },

        getOptionalSectionValueFromDocument(document, id) {
            for (const optionalSectionEntry of document.optional_section_entries) {
                if (optionalSectionEntry.optional_section.id === id) {
                    return optionalSectionEntry.value;
                }
            }
        },

        getOptionalSectionFromDocument(document, id) {
            for (const optionalSectionEntry of document.optional_section_entries) {
                if (optionalSectionEntry.optional_section.id === id) {
                    return optionalSectionEntry;
                }
            }
        },

        getPlaceholderFromDocument(document, id) {
            for (const placeholderEntry of document.placeholder_entries) {
                if (placeholderEntry.placeholder.id === id) {
                    return placeholderEntry;
                }
            }
            for (const optionalSectionEntry of document.optional_section_entries) {
                for (const placeholderEntry of optionalSectionEntry.optional_section.children) {
                    if (placeholderEntry.placeholder.id === id) {
                        return placeholderEntry;
                    }
                }
            }
        },
    },
};
</script>

<style>
.info-entry {
    display: grid;
    grid-template-columns: 150px minmax(300px, 650px);
    grid-gap: 10px;
}
</style>
