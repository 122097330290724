<template>
    <v-form v-model="valid" ref="templateMetaDataForm">
        <v-text-field
            v-model="templateMetaData.templateName"
            label="Template name"
            :rules="nameRules"
        ></v-text-field>
        <v-textarea
            v-model="templateMetaData.templateDescription"
            label="Template description"
            :rules="descriptionRules"
        ></v-textarea>
        <v-checkbox
            v-model="templateMetaData.contactDetails.includePersonContact"
            label="Include person contact for this template."
            class="pl-3"
        ></v-checkbox>
        <v-checkbox
            v-model="templateMetaData.contactDetails.includeCompanyContact"
            label="Include company contact for this template."
            class="pl-3"
        ></v-checkbox>
        <v-btn class="mb-2" color="accent" @click="submit"> Continue </v-btn>
    </v-form>
</template>

<script>
export default {
    name: 'Step2TemplateMetaData',
    emits: ['submit'],
    props: {
        value: {
            type: Object,
        },
    },
    data: () => ({
        valid: true,
        templateMetaData: {
            templateName: '',
            templateDescription: '',
            contactDetails: {
                includePersonContact: false,
                includeCompanyContact: false,
            },
        },

        nameRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.trim().length > 0) || 'This field may not be blank',
            (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
        ],

        descriptionRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.trim().length > 0) || 'This field may not be blank',
            (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
        ],
    }),
    created() {
        this.templateMetaData = this.value;
    },
    watch: {
        templateMetaData: function (value) {
            this.$emit('input', value);
        },
    },
    methods: {
        async submit() {
            await this.$refs.templateMetaDataForm.validate();
            if (!this.valid) return;
            this.$emit('submit');
        },
    },
};
</script>
