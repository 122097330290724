import CreateDocument from '@/views/TemplatesModule/Documents/NewDocument';
import DocumentRegistry from '@/views/TemplatesModule/Documents/DocumentRegistry';
import EditDocument from '@/views/TemplatesModule/Documents/EditDocument';

export default [
    {
        path: '/templates/:templateid/createdocument',
        name: 'createdocument',
        component: CreateDocument,
        meta: {
            auth: true,
            capabilities: ['CreateDocuments'],
        },
    },
    {
        path: '/documents',
        name: 'documents',
        component: DocumentRegistry,
        meta: {
            auth: true,
            capabilities: ['ReadDocuments'],
        },
    },
    {
        path: '/documents/:documentid/editdocument',
        name: 'editdocument',
        component: EditDocument,
        meta: {
            auth: true,
            capabilities: ['UpdateDocuments'],
        },
    },
];
