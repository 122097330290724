import AccountInformation from '@/views/AccountInformation';
import Login from '@/views/Login';
import CompanyManagement from '@/views/CompanyManagement';

export default [
    {
        path: '/account',
        name: 'account',
        component: AccountInformation,
        meta: {
            auth: true,
        },
    },
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    {
        path: '/companies',
        name: 'companies',
        component: CompanyManagement,
        meta: {
            auth: true,
        },
    },
];
