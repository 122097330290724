<template>
    <v-app>
        <div class="main-wrapper">
            <NavigationBar
                v-if="this.$route.name !== 'login'"
                class="navigation-bar"
            ></NavigationBar>
            <v-main class="content">
                <v-container fluid class="pa-6" fill-height>
                    <router-view></router-view>
                </v-container>
            </v-main>
        </div>
    </v-app>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
    created() {
        this.heartbeat();
    },
    methods: {
        ...mapActions(['heartbeat', 'checkAuth']),
    },
    computed: {
        ...mapGetters(['isAuthenticating']),
    },
    components: {
        NavigationBar,
    },
};
</script>

<style>
.main-wrapper {
    display: grid;
    grid-template-columns: 56px auto;
    grid-template-rows: 100vh;
}

.navigation-bar {
    grid-column: 1;
}

.content {
    grid-column: 2;
}
</style>
