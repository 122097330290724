<template>
    <v-row justify="center">
        <img src="/images/logo.png" class="logo" />
        <v-col cols="10" sm="8">
            <div class="pb-5 text-h3" v-if="$store.getters.isSystemAdministrator">
                Welcome System Administrator!
            </div>
            <h1 class="pb-5 text-h3" v-else>Welcome {{ user.name }}!</h1>
            <a
                v-if="$store.getters.isSystemAdministrator"
                class="pr-4"
                href="/documents/systemadmin-manual.pdf"
                download="System administrator manual.pdf"
                style="text-decoration: none"
            >
                <v-btn color="accent" class="mt-3">
                    <v-icon class="pr-3">mdi-download</v-icon>
                    System administrator manual
                </v-btn>
            </a>
            <a
                href="/documents/user-manual.pdf"
                download="User manual.pdf"
                style="text-decoration: none"
            >
                <v-btn color="accent" class="mt-3">
                    <v-icon class="pr-3">mdi-download</v-icon>
                    User manual
                </v-btn>
            </a>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['user']),
    },
};
</script>

<style scoped>
.logo {
    position: fixed;
    left: 0;
    top: 10vh;
    height: 80vh;
    transform: translateX(-35%);
    opacity: 0.5;
    z-index: 0;
}

.main-content {
    z-index: 1;
}
</style>
