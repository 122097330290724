<template>
    <div>
        <v-form v-model="valid" ref="optionalSectionsForm">
            <v-data-table
                :headers="optionalSectionsHeaders"
                :items="optionalSections"
                disable-pagination
                hide-default-footer
                fixed-header
                class="mb-5"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.name }}</td>
                        <td>
                            <v-text-field
                                dense
                                v-model="item.label"
                                :rules="labelRules"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-checkbox color="black" v-model="item.default"></v-checkbox>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-form>
        <v-btn class="mb-2" color="accent" @click="submit">Continue</v-btn>
    </div>
</template>

<script>
import { labelRules } from '@/utils/FieldValidations';

export default {
    name: 'Step3OptionalSections',
    emits: ['submit'],
    props: {
        value: {
            type: Array,
        },
    },
    created() {
        this.templateElements = this.value;
    },
    data: () => ({
        valid: true,
        labelRules: labelRules,
        templateElements: [],
        optionalSectionsHeaders: [
            {
                text: 'Detected optional section',
                value: 'name',
                sortable: false,
            },
            { text: 'Label name', value: 'label', sortable: false },
            { text: 'Default', value: 'default', sortable: false },
        ],
    }),
    computed: {
        optionalSections() {
            const optionalSections = [];
            for (const element of this.templateElements)
                if (element.type === 'optional_section') optionalSections.push(element);
            return optionalSections;
        },
    },
    watch: {
        templateElements: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.templateElements = value;
        },
    },
    methods: {
        async submit() {
            await this.$refs.optionalSectionsForm.validate();
            if (!this.valid) return;
            this.$emit('submit');
        },
    },
};
</script>
