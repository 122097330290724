<template>
    <div>
        <v-data-table
            v-if="loaded"
            height="260px"
            :headers="headers"
            :items="contacts"
            :search="searchQuery"
            v-model="selected"
            item-key="id"
            disable-pagination
            hide-default-footer
            fixed-header
            show-select
            single-select
            class="mr-3"
            mobile-breakpoint="0"
            :no-data-text="noContactsText"
        >
            <template v-slot:top>
                <v-text-field
                    v-model="searchQuery"
                    append-icon="mdi-magnify"
                    :label="searchLabelText"
                    single-line
                    hide-details
                ></v-text-field>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import ContactsApi from '@/api/Contacts/ContactsApi';
import { find } from 'lodash/collection';

export default {
    name: 'ContactsSelector',
    data() {
        return {
            contacts: [],
            searchQuery: '',
            selected: [],
            loaded: false,
        };
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        /**
         * Default loads person contacts.
         */
        loadCompanies: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        headers() {
            const headers = [
                { text: 'First name', value: 'first_name', sortable: false },
                { text: 'Last name', value: 'last_name', sortable: false },
                { text: 'City', value: 'city', sortable: false },
                { text: 'Position', value: 'position', sortable: false },
            ];
            if (this.loadCompanies) {
                headers.unshift({ text: 'Name', value: 'name', sortable: false });
            }
            return headers;
        },
        searchLabelText() {
            return `Search ${this.loadCompanies ? 'company' : 'person'} contacts`;
        },
        noContactsText() {
            const type = this.loadCompanies ? 'company' : 'person';
            return `There are no ${type} contacts. Add a ${type} contact before creating this document.`;
        },
    },
    methods: {
        async loadContacts() {
            const personContactsApi = new ContactsApi();
            if (this.loadCompanies) {
                this.contacts = await personContactsApi.getCompanyContacts();
            } else {
                this.contacts = await personContactsApi.getPersonContacts();
            }

            if (this.value !== '') {
                this.selected = [find(this.contacts, { id: this.value })];
            }

            this.loaded = true;
        },
    },
    created() {
        this.loadContacts();
    },
    watch: {
        selected() {
            if (this.loaded) {
                const selectedId = this.selected.length === 1 ? this.selected[0].id : '';
                this.$emit('input', selectedId);
            }
        },
    },
};
</script>

<style scoped></style>
