<template>
    <v-form>
        <v-stepper class="main-stepper-view elevation-5" v-model="currentStepNumber" vertical>
            <v-toolbar dark flat color="primary">
                <v-toolbar-title>{{ newTemplate ? 'New' : 'Edit' }} Template</v-toolbar-title>
            </v-toolbar>
            <v-stepper-step
                color="primary"
                :editable="currentStepNumber > 1 || !newTemplate"
                :complete="currentStepNumber > 1 || !newTemplate"
                step="1"
            >
                <template v-if="newTemplate">Upload a Word document</template>
                <template v-else>Upload a new Word document</template>
            </v-stepper-step>
            <v-stepper-content step="1">
                <step-1-upload-word-file @submit="submittedStep1DetectTemplateElements" />
            </v-stepper-content>

            <v-stepper-step
                step="2"
                color="primary"
                :complete="currentStepNumber > 2 || !newTemplate"
                :editable="currentStepNumber > 2 || !newTemplate"
                >Provide template information
            </v-stepper-step>
            <v-stepper-content step="2">
                <step-2-template-meta-data
                    ref="templateMetaDataStep"
                    :oldTemplate="oldTemplate"
                    v-model="templateMetaData"
                    @submit="currentStepNumber = 3"
                />
            </v-stepper-content>

            <v-stepper-step
                step="3"
                color="primary"
                :complete="currentStepNumber > 3 || !newTemplate"
                :editable="currentStepNumber > 3 || !newTemplate"
                >Identify optional document parts
            </v-stepper-step>
            <v-stepper-content step="3">
                <step-3-optional-sections
                    ref="optionalSectionsStep"
                    v-model="templateElements"
                    @submit="currentStepNumber = 4"
                />
            </v-stepper-content>

            <v-stepper-step
                step="4"
                color="primary"
                :complete="currentStepNumber > 4 || !newTemplate"
                :editable="currentStepNumber > 4 || !newTemplate"
                >Identify document variables
            </v-stepper-step>
            <v-stepper-content step="4">
                <step-4-placeholders
                    ref="placeholdersStep"
                    v-model="templateElements"
                    :contactDetails="templateMetaData.contactDetails"
                    @submit="currentStepNumber = 5"
                />
            </v-stepper-content>

            <v-stepper-step
                color="primary"
                step="5"
                :complete="!newTemplate"
                :editable="!newTemplate"
                >Indicate access
            </v-stepper-step>
            <v-stepper-content step="5">
                <step-5-access v-model="access" @submit="submitTemplate" />
            </v-stepper-content>

            <v-row>
                <v-spacer></v-spacer>
                <v-btn class="ml-7 mt-10" text @click="showCancelConfirmMessage = true"
                    >Cancel</v-btn
                >
                <v-btn
                    class="ml-3 mt-10 mr-10"
                    @click="submitTemplate"
                    color="accent"
                    :disabled="newTemplate && currentStepNumber < 5"
                    >Finish</v-btn
                >
            </v-row>
        </v-stepper>
        <v-dialog v-model="showCancelConfirmMessage" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline"
                        >Exit {{ newTemplate ? 'new' : 'edit' }} template process</span
                    >
                </v-card-title>
                <v-card-text class="pb-0">
                    Are you sure you want exit? All filled in values will be lost.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        dark
                        color="secondary"
                        class="elevation-3 ma-3"
                        @click="showCancelConfirmMessage = false"
                        >No
                    </v-btn>
                    <v-btn dark color="accent" class="elevation-3 ma-3" :to="{ name: 'templates' }"
                        >Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import Step1UploadWordFile from './Step1UploadWordFile.vue';
import Step2TemplateMetaData from './Step2TemplateMetaData.vue';
import Step3OptionalSections from './Step3OptionalSections.vue';
import Step4Placeholders from './Step4Placeholders.vue';
import Step5Access from './Step5Access.vue';
import { mergeOldAndNewTemplateElements } from '@/utils/TemplateDataProcessor';
import { find } from 'lodash';
import GroupManagement from '@/api/AccessManagement/GroupManagement';
import UserManagement from '@/api/AccessManagement/UserManagement';
import { retrieveAllTemplateElements } from '@/utils/TemplateDataProcessor';

export default {
    components: {
        Step1UploadWordFile,
        Step2TemplateMetaData,
        Step3OptionalSections,
        Step4Placeholders,
        Step5Access,
    },
    props: {
        newTemplate: Boolean,
        oldTemplate: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    async created() {
        this.currentStepNumber = this.newTemplate ? 1 : 2;
        if (this.oldTemplate) {
            this.templateMetaData.templateName = this.oldTemplate.name;
            this.templateMetaData.templateDescription = this.oldTemplate.description;
            this.templateMetaData.contactDetails.includePersonContact =
                this.oldTemplate.has_person_contact;
            this.templateMetaData.contactDetails.includeCompanyContact =
                this.oldTemplate.has_company_contact;
            this.folder = this.oldTemplate.folder;
            this.wordDocumentId = this.oldTemplate.word_doc_id;

            const groupManagement = new GroupManagement();
            const userManagement = new UserManagement();
            const groups = await groupManagement.getGroups();
            const users = await userManagement.getUsers();
            this.access.groups = this.oldTemplate.groups.map(function (groupId) {
                return find(groups, { id: groupId });
            });
            this.access.users = this.oldTemplate.users.map(function (userId) {
                return find(users, { id: userId });
            });

            this.templateElements = retrieveAllTemplateElements(this.oldTemplate.tree, true, false);
        }
    },
    data() {
        return {
            currentStepNumber: 1,
            showCancelConfirmMessage: false,
            folder: null,

            // Step 1 - Upload word document and detect elements
            wordDocumentId: null,

            // Step 2 - Add template meta data
            templateMetaData: {
                templateName: '',
                templateDescription: '',
                contactDetails: {
                    includePersonContact: false,
                    includeCompanyContact: false,
                },
            },

            // Step 3 / 4 - Indicate optional sections and placeholders
            templateElements: [],

            // Step 5 - Indicate access
            access: {
                groups: [],
                users: [this.$store.getters.user],
            },
        };
    },
    methods: {
        submittedStep1DetectTemplateElements({ templateElements, wordDocumentId }) {
            const oldTemplateElements = this.oldTemplate
                ? retrieveAllTemplateElements(this.oldTemplate.tree, true, false)
                : [];
            this.templateElements = mergeOldAndNewTemplateElements(
                oldTemplateElements,
                templateElements
            );
            this.wordDocumentId = wordDocumentId;
            this.currentStepNumber = 2;
        },

        async getInvalidStep() {
            if (!(await this.$refs.templateMetaDataStep.$refs.templateMetaDataForm.validate())) {
                return 2;
            }
            if (!(await this.$refs.optionalSectionsStep.$refs.optionalSectionsForm.validate())) {
                return 3;
            }
            if (!(await this.$refs.placeholdersStep.$refs.placeholdersForm.validate())) {
                return 4;
            }
        },

        async submitTemplate() {
            const invalidStep = await this.getInvalidStep();
            if (invalidStep) {
                this.currentStepNumber = invalidStep;
                return;
            }

            const templateData = {
                word_doc_id: this.wordDocumentId,
                name: this.templateMetaData.templateName,
                folder: this.folder,
                description: this.templateMetaData.templateDescription,
                elements: this.templateElements,
                has_person_contact: this.templateMetaData.contactDetails.includePersonContact,
                has_company_contact: this.templateMetaData.contactDetails.includeCompanyContact,
                groups: this.access.groups.map(function (group) {
                    return group.id;
                }),
                users: this.access.users.map(function (user) {
                    return user.id;
                }),
            };

            this.$emit('submit', templateData);
        },
    },
};
</script>
