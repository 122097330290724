<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card>
                <v-tabs dark value="/contacts" color="white" background-color="primary">
                    <v-tab :to="{ name: 'person.contacts' }">Persons</v-tab>
                    <v-tab :to="{ name: 'company.contacts' }">Companies</v-tab>

                    <v-tab-item value="/contacts/persons">
                        <router-view
                            v-if="$router.currentRoute.path === '/contacts/persons'"
                        ></router-view>
                    </v-tab-item>

                    <v-tab-item value="/contacts/companies">
                        <router-view
                            v-if="$router.currentRoute.path === '/contacts/companies'"
                        ></router-view>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Contacts',
    computed: {
        ...mapGetters(['userCan']),
    },
};
</script>
