<template>
    <v-row class="align-self-start">
        <v-col>
            <v-data-table
                height="300px"
                :headers="groupsHeaders"
                :items="groups"
                :search="searchGroups"
                disable-pagination
                hide-default-footer
                fixed-header
                mobile-breakpoint="0"
            >
                <template v-slot:top>
                    <div
                        class="ma-4"
                        v-bind:class="{
                            searchbar: userCan('CreateGroups'),
                            'searchbar-no-create': !userCan('CreateGroups'),
                        }"
                    >
                        <v-text-field
                            v-model="searchGroups"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-btn
                            dark
                            width="150px"
                            class="mt-3"
                            :to="{ name: 'newgroup' }"
                            color="accent"
                            v-if="userCan('CreateGroups')"
                            >New Group
                        </v-btn>
                    </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editGroup(item)"
                        v-if="userCan('UpdateGroups')"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteGroup(item)" v-if="userCan('DeleteGroups')">
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="popup.active" max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ popup.title }}</span>
                    </v-card-title>
                    <v-card-text class="pb-0">{{ popup.text }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            color="secondary"
                            class="elevation-3 ma-4"
                            @click="popup.active = false"
                            >Cancel
                        </v-btn>
                        <v-btn color="error" class="elevation-3 ma-4" @click="confirm()"
                            >Delete</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupManagement from '@/api/AccessManagement/GroupManagement';
import router from '@/router';

export default {
    data() {
        return {
            panel: [],
            popup: {
                active: false,
                title: null,
                text: null,
                groupid: null,
            },
            searchGroups: '',
            groupsHeaders: [
                { text: 'Group Name', sortable: false, value: 'name', width: '50%' },
                { text: 'Users', sortable: false, value: 'users' },
                { text: '', value: 'actions', sortable: false, align: 'end' },
            ],
            groups: [],
        };
    },
    methods: {
        async getAllGroups() {
            const groupManagement = new GroupManagement();
            this.groups = await groupManagement.getGroups();
        },
        editGroup(group) {
            router.push({
                name: 'editgroup',
                params: {
                    groupid: group.id,
                },
            });
        },
        deleteGroup(group) {
            this.popup.active = true;
            this.popup.title = 'Delete ' + group.name;
            this.popup.text = 'Are you sure you want to delete this group?';
            this.popup.groupid = group.id;
        },
        async confirm() {
            const groupManagement = new GroupManagement();
            await groupManagement.deleteGroup(this.popup.groupid);
            this.popup.active = false;
            await this.getAllGroups();
        },
    },
    computed: {
        ...mapGetters(['userCan']),
    },
    beforeMount() {
        this.getAllGroups();
    },
};
</script>

<style>
.searchbar {
    display: grid;
    grid-template-columns: minmax(200px, 9fr) 1fr;
    grid-column-gap: 35px;
}

.searchbar-no-create {
    display: grid;
    grid-template-columns: minmax(200px, 1fr);
    grid-column-gap: 35px;
}
</style>
