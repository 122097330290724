<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="document-registry elevation-5">
                <v-sheet dark class="pl-6 pb-4 pr-4 pt-2 top" color="primary">
                    <v-text-field
                        v-model="search"
                        label="Search documents"
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                    <v-btn
                        width="100%"
                        class="elevation-3 mt-4"
                        @click="newFolderDialog = true"
                        color="secondary"
                    >
                        <v-icon>mdi-folder-plus</v-icon>
                    </v-btn>
                </v-sheet>
                <v-card-text>
                    <v-treeview
                        :items="documents"
                        :search="search"
                        :filter="filter"
                        :open.sync="open"
                        item-key="id"
                        hoverable
                        rounded
                        transition
                    >
                        <!--Prepend folder or document icon in front of row in tree view (depending on row type)-->
                        <template v-slot:prepend="{ item, open }">
                            <v-icon
                                @click="clickedNodeInTreeView(item)"
                                v-text="
                                    `mdi-${
                                        item.type === 'folder'
                                            ? open && item.children.length > 0
                                                ? 'folder-open'
                                                : 'folder'
                                            : 'file'
                                    }`
                                "
                            ></v-icon>
                        </template>
                        <template v-slot:label="{ item }">
                            <a class="link" @click="clickedNodeInTreeView(item)">
                                <div width="100%">{{ item.name }}</div>
                            </a>
                        </template>
                        <template v-slot:append="{ item }">
                            <v-icon
                                v-if="item.type === 'folder'"
                                @click="
                                    currentlySelectedFolder = item;
                                    folderInformationDialog = true;
                                "
                                >mdi-pencil
                            </v-icon>
                        </template>
                    </v-treeview>
                </v-card-text>
                <v-row justify="center">
                    <!--The dialog below opens when on the new folder button-->
                    <v-dialog v-model="newFolderDialog" max-width="600px">
                        <NewFolderDialog
                            @create-folder="createFolder"
                            v-bind:tree-structure="documents"
                        ></NewFolderDialog>
                    </v-dialog>
                    <!--The dialog below opens when pressing the move to folder button-->
                    <v-dialog v-model="elementMoveDialog" max-width="600px">
                        <MoveElement
                            @move-element="moveElement"
                            v-bind:tree-structure="documents"
                            v-bind:element="elementToMove"
                        ></MoveElement>
                    </v-dialog>
                    <!--The dialog below opens when the user clicks on a document in the treeview-->
                    <v-dialog v-model="documentInformationDialog" max-width="600px">
                        <DocumentInformation
                            v-if="currentlySelectedDocumentLoaded"
                            v-bind:document="currentlySelectedDocument"
                            @delete="deleteDocument"
                            @move-document="showMoveDialog"
                        >
                        </DocumentInformation>
                    </v-dialog>
                    <!--The dialog below opens when the user clicks on a folder in the treeview-->
                    <v-dialog v-model="folderInformationDialog" max-width="600px">
                        <FolderInformation
                            v-bind:folder="currentlySelectedFolder"
                            @delete-folder="deleteFolder"
                            @move-folder="showMoveDialog"
                            @update-folder="editFolder"
                        ></FolderInformation>
                    </v-dialog>
                    <!--The snackbar below is shown when the user tries to delete a folder which is not empty-->
                    <v-snackbar
                        color="error"
                        v-model="showDeleteNonEmptyFolderErrorMessage"
                        :timeout="3000"
                        bottom
                        transition="fade-transition"
                        >You can't delete a folder which is not empty
                    </v-snackbar>
                    <!--The snackbar below is shown when the user tries to delete a folder which is not empty-->
                    <v-snackbar
                        color="error"
                        v-model="showDeleteNonEmptyFolderErrorMessage2"
                        :timeout="3000"
                        bottom
                        transition="fade-transition"
                        >This folder contains documents you have no access to, you can't delete it.
                    </v-snackbar>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import NewFolderDialog from '@/components/TemplatesModule/NewFolder.vue';
import MoveElement from '@/components/TemplatesModule/MoveElement';
import FolderInformation from '@/components/TemplatesModule/FolderInformation';
import DocumentInformation from '@/components/TemplatesModule/DocumentInformation';
import DocumentManagement from '@/api/TemplatesModule/DocumentManagement';

export default {
    components: {
        DocumentInformation,
        FolderInformation,
        MoveElement,
        NewFolderDialog,
    },
    methods: {
        async createFolder(name, parentid) {
            this.newFolderDialog = false;
            await new DocumentManagement().createFolder(name, parentid);
            await this.getFolderStructureRepresentation();
        },
        async editFolder(folder) {
            const documentManagement = new DocumentManagement();
            await documentManagement.editFolder(folder.id, {
                name: folder.name,
            });
            this.folderInformationDialog = false;
            await this.getFolderStructureRepresentation();
        },
        showMoveDialog(element) {
            this.elementToMove = element;
            this.elementMoveDialog = true;
            this.documentInformationDialog = false;
            this.folderInformationDialog = false;
        },
        async moveElement(element, folderid) {
            if (folderid === undefined) {
                folderid = null;
            }
            const documentManagement = new DocumentManagement();
            if (element.type === 'folder') {
                await documentManagement.editFolder(element.id, { parent: folderid });
                this.activeParentFolderToMoveTo = [null];
            } else if (element.type === 'document') {
                await documentManagement.editDocument({ folder: folderid }, element.id);
                this.activeParentFolderToMoveTo = [null];
            }
            this.elementMoveDialog = false;
            this.documentInformationDialog = false;
            await this.getFolderStructureRepresentation();
        },
        async clickedNodeInTreeView(item) {
            //If we pressed on a document
            if (item.type === 'document') {
                //Show the document information dialog and set
                //the currently selected document to the selected item.
                this.documentInformationDialog = true;

                this.currentlySelectedDocumentLoaded = false;

                const documentManagement = new DocumentManagement();
                this.currentlySelectedDocument =
                    await documentManagement.getSpecificDocumentMetaData(item.id);
                this.currentlySelectedDocument['type'] = 'document';

                this.currentlySelectedDocumentLoaded = true;
            } else {
                if (this.open.indexOf(item.id) > -1) {
                    this.open.splice(this.open.indexOf(item.id), 1);
                } else {
                    this.open.push(item.id);
                }
            }
        },
        async deleteFolder(folder) {
            if (folder.children.length > 0) {
                this.showDeleteNonEmptyFolderErrorMessage = true;
            } else {
                var documentManagement = new DocumentManagement();
                const response = await documentManagement.deleteFolder(folder.id);
                if (response === false) {
                    this.showDeleteNonEmptyFolderErrorMessage2 = true;
                }
                await this.getFolderStructureRepresentation();
            }
            this.folderInformationDialog = false;
        },

        async deleteDocument(document) {
            const documentManagement = new DocumentManagement();
            await documentManagement.deleteDocument(document.id);
            await this.getFolderStructureRepresentation();
            this.documentInformationDialog = false;
        },
        async getFolderStructureRepresentation() {
            const documentManagement = new DocumentManagement();
            const folderStructure = await documentManagement.getDocumentsWithFolderStructure();
            let folderStructureRepresentation = [];
            folderStructureRepresentation = this.createFolderStructureRepresentation(
                folderStructure.folders,
                folderStructure.documents
            );
            this.documents = folderStructureRepresentation;
            this.folderIds = this.getAllFolderIds(this.documents);
        },
        getAllFolderIds(root) {
            let folderIds = [];
            for (const element of root) {
                if (element.type === 'folder') {
                    folderIds.push(element.id);
                    folderIds = folderIds.concat(this.getAllFolderIds(element.children));
                }
            }
            return folderIds;
        },
        findParent(nodeOfWhichParentShouldBeFound) {
            for (let i = 0; i < this.documents.length; i++) {
                const found = this.recursiveFindParent(
                    this.documents[i],
                    nodeOfWhichParentShouldBeFound
                );
                if (found != null) {
                    return found;
                }
            }
            return null;
        },

        recursiveFindParent(currentNode, nodeToFind) {
            if (currentNode.children === undefined) return null;
            if (currentNode.children.indexOf(nodeToFind) > -1) {
                return currentNode;
            } else {
                for (const child of currentNode.children) {
                    const found = this.recursiveFindParent(child, nodeToFind);
                    if (found != null) {
                        return currentNode;
                    }
                }
                return null;
            }
        },
        createFolderStructureRepresentation(folders, documents) {
            const folderStructureRepresentation = [];
            for (const folder of folders) {
                folderStructureRepresentation.push({
                    type: 'folder',
                    id: folder.id,
                    name: folder.name,
                    children: this.createFolderStructureRepresentation(
                        folder.folders,
                        folder.documents
                    ),
                });
            }
            for (const document of documents) {
                document['type'] = 'document';
                folderStructureRepresentation.push(document);
            }
            return folderStructureRepresentation;
        },
    },
    data: () => ({
        folderIds: [],
        activeParentFolderToMoveTo: [null],
        activeParentFolderForNewFolder: [null],
        //Holds the search query entered in the top search bar.
        search: '',
        //Indicates whether or not the documents information dialog should be shown.
        documentInformationDialog: false,
        //Indicates whether or not the documents confirm deletion dialog should be shown.
        showDeleteDocumentConfirmationDialog: false,

        //Indicates whether or not the move to folder dialog should be shown.
        elementMoveDialog: false,
        //Indicates whether or not folder information dialog should be shown.
        folderInformationDialog: false,
        //Holds the text entered in the name textfield of the folder information dialog.
        folderInformationDialogName: null,
        //Indicates whether or not the new folder dialog should be shown.
        newFolderDialog: false,
        //v-model attribute of textfield upon creating a new folder.
        nameOfNewFolder: null,
        //Indicates whether we should show the delete non-empty folder error messages or not
        showDeleteNonEmptyFolderErrorMessage: false,
        showDeleteNonEmptyFolderErrorMessage2: false,
        //Holds the document object which is clicked on.
        currentlySelectedDocument: null,
        currentlySelectedDocumentLoaded: false,
        //Holds the folder object which is clicked on.
        currentlySelectedFolder: null,
        //Holds the element to move, i.e. the document or folder for which we clicked on the move button.
        elementToMove: null,
        //Holds the tree hierarchy of documents and folders
        documents: [],
        open: [],
    }),
    computed: {
        ...mapGetters(['userCan']),
        /**
         * Filter is used to filter the tree hierarchy using the search data
         * entered by the user in the top search bar.
         */
        filter() {
            return (item, search, textKey) =>
                item[textKey].toLowerCase().indexOf(search.toLowerCase()) > -1;
        },
    },
    async created() {
        await this.getFolderStructureRepresentation();
    },
    watch: {
        search: function (val) {
            if (val !== '') this.open = this.folderIds;
        },
    },
};
</script>

<style scoped>
.link {
    color: inherit;
}

.top {
    display: grid;
    grid-template-columns: minmax(300px, auto) 75px;
    grid-template-rows: auto;
    grid-gap: 20px;
}
</style>
