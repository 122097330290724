var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("New folder")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Folder name","rules":[function (v) { return !!v || 'This field is required'; }]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createFolder.apply(null, arguments)}},model:{value:(_vm.newFolderName),callback:function ($$v) {_vm.newFolderName=$$v},expression:"newFolderName"}})],1),(_vm.folderOnlyTreeStructure.length > 0)?_c('div',[_vm._v(" Select a folder in which you want to place the new folder (optional). "),_c('v-treeview',{attrs:{"items":_vm.folderOnlyTreeStructure,"item-key":"id","hoverable":"","rounded":"","transition":"","open-all":"","activatable":"","active":_vm.newFolderParent},on:{"update:active":function($event){_vm.newFolderParent=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-icon',{domProps:{"textContent":_vm._s(
                            ("mdi-" + (open && item.children.length > 0 ? 'folder-open' : 'folder'))
                        )}})]}},{key:"label",fn:function(ref){
                        var item = ref.item;
return [(item.type === 'folder')?_c('div',[_vm._v(_vm._s(item.name))]):_vm._e()]}}],null,false,2766284398)})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4 mb-3",attrs:{"small":"","dark":"","color":"accent"},on:{"click":_vm.createFolder}},[_vm._v("Create")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }