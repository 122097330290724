import ApiConsumer from '../ApiConsumer';

export default class CompanyManagement extends ApiConsumer {
    /**
     * Retrieves all companies
     */
    async getCompanies() {
        try {
            const response = await this.api.get('/companies/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Creates a new company
     */
    async createCompany(newCompany) {
        try {
            const response = await this.api.post('/companies/', {
                name: newCompany.name,
                admin_first_name: newCompany.adminFirstName,
                admin_last_name: newCompany.adminLastName,
                admin_email: newCompany.adminEmail,
                admin_password: newCompany.adminPassword,
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Edits the name of a company
     */
    async editCompanyName(name, id) {
        try {
            const response = await this.api.patch('/companies/' + id + '/', {
                name: name,
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Deletes a company
     */
    async deleteCompany(id) {
        try {
            const response = await this.api.delete('/companies/' + id + '/');
            return response.data;
        } catch (error) {
            return false;
        }
    }
}
