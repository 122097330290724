import ApiConsumer from '../ApiConsumer';

export default class UserManagement extends ApiConsumer {
    /**
     * Retrieves all users
     */
    async getUsers() {
        try {
            const response = await this.api.get('/users/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Retrieves a user by their user id
     */
    async getSpecificUser(userId) {
        try {
            const response = await this.api.get('/users/' + userId + '/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Creates a specific user
     */
    async createUser(user) {
        try {
            const response = await this.api.post('/users/', user);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Edits a specific user
     */
    async editUser(editedUser, id) {
        try {
            const response = await this.api.patch('/users/' + id + '/', editedUser);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Deletes a specific user
     */
    async deleteUser(id) {
        try {
            const response = await this.api.delete('/users/' + id + '/');
            return response.data;
        } catch (error) {
            return false;
        }
    }
}
