<template>
    <v-dialog v-model="active" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-card-text class="pb-0">{{ message }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="secondary" class="elevation-3 ma-4" @click="closePopup">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'MessagePopup',
    data() {
        return {
            active: false,
        };
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
    },
    methods: {
        closePopup() {
            this.active = false;
        },
    },
    created() {
        this.active = this.value;
    },
    watch: {
        active() {
            this.$emit('input', this.active);
        },
        value() {
            this.active = this.value;
        },
    },
};
</script>

<style scoped></style>
