import NewRole from '@/views/AccessManagement/Roles/NewRole';
import EditRole from '@/views/AccessManagement/Roles/EditRole';

export default [
    {
        path: '/usermanagement/newrole',
        name: 'newrole',
        component: NewRole,
        meta: {
            auth: true,
            capabilities: ['CreateRoles'],
        },
    },

    {
        path: '/usermanagement/:roleid/editrole',
        name: 'editrole',
        component: EditRole,
        meta: {
            auth: true,
            capabilities: ['UpdateRoles'],
        },
    },
];
