<template>
    <v-data-table
        height="260px"
        :headers="groupsHeaders"
        :items="groups"
        :search="searchQuery"
        v-model="model"
        item-key="id"
        disable-pagination
        hide-default-footer
        fixed-header
        show-select
        class="mr-3"
        mobile-breakpoint="0"
    >
        <template v-slot:top>
            <v-text-field
                v-model="searchQuery"
                append-icon="mdi-magnify"
                label="Search group"
                single-line
                hide-details
                class="mb-5"
            ></v-text-field>
        </template>
    </v-data-table>
</template>

<script>
import GroupManagement from '@/api/AccessManagement/GroupManagement';

export default {
    name: 'GroupsSelector',
    data() {
        return {
            groupsHeaders: [
                { text: 'Name', sortable: false, value: 'name' },
                { text: 'Users', sortable: false, value: 'users' },
            ],
            searchQuery: '',
            groups: [],
            model: [],
        };
    },

    props: {
        /**
         * Array of group objects.
         */
        value: {
            type: Array,
            required: true,
        },
    },

    watch: {
        model() {
            this.$emit(
                'input',
                this.model.map((g) => g.id)
            );
        },
    },

    async created() {
        const groupManagement = new GroupManagement();
        this.groups = await groupManagement.getGroups();
        this.model = this.groups.filter((g) => this.value.includes(g.id));
    },
};
</script>

<style scoped></style>
