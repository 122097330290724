<template>
    <v-row class="align-self-start">
        <v-col>
            <v-data-table
                height="500px"
                :headers="usersHeaders"
                :items="usersContent"
                :search="searchUsers"
                disable-pagination
                hide-default-footer
                fixed-header
                mobile-breakpoint="0"
            >
                <template v-slot:top>
                    <div
                        class="ma-4"
                        v-bind:class="{
                            searchbar: userCan('CreateUsers'),
                            'searchbar-no-create': !userCan('CreateUsers'),
                        }"
                    >
                        <v-text-field
                            v-model="searchUsers"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-btn
                            class="mt-3"
                            dark
                            :to="{ name: 'newuser' }"
                            width="150px"
                            color="accent"
                            v-if="userCan('CreateUsers')"
                            >New User</v-btn
                        >
                    </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editUser(item)" v-if="userCan('UpdateUsers')"
                        >mdi-pencil</v-icon
                    >
                    <v-icon
                        small
                        @click="deleteUser(item)"
                        v-if="
                            !(item.role_name === 'Admin' && numberOfAdminUsers < 2) &&
                            userCan('DeleteUsers')
                        "
                        >mdi-delete</v-icon
                    >
                </template>
            </v-data-table>
            <v-dialog v-model="popup.active" max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ popup.title }}</span>
                    </v-card-title>
                    <v-card-text class="pb-0">{{ popup.text }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            color="secondary"
                            class="elevation-3 ma-4"
                            @click="popup.active = false"
                            >Cancel
                        </v-btn>
                        <v-btn color="error" class="elevation-3 ma-4" @click="confirm()"
                            >Delete</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import UserManagement from '@/api/AccessManagement/UserManagement';
import router from '@/router';

export default {
    data() {
        return {
            panel: [],
            popup: {
                active: false,
                title: null,
                text: null,
                type: null,
                userid: null,
            },
            searchUsers: '',
            usersHeaders: [
                { text: 'Name', sortable: false, value: 'name', width: '30%' },
                { text: 'Email', sortable: false, value: 'email', width: '30%' },
                {
                    text: 'Role',
                    sortable: false,
                    value: 'role_name',
                    align: ' d-none d-sm-table-cell',
                    width: '15%',
                },
                {
                    text: 'Group(s)',
                    sortable: false,
                    align: ' d-none d-md-table-cell',
                    value: 'groups',
                },
                { text: '', value: 'actions', sortable: false, align: 'end' },
            ],
            usersContent: [],
            numberOfAdminUsers: 0,
        };
    },
    methods: {
        async getAllUsers() {
            const userManagement = new UserManagement();
            this.usersContent = await userManagement.getUsers();
            this.numberOfAdminUsers = 0;
            for (const user of this.usersContent)
                if (user.role_name === 'Admin') this.numberOfAdminUsers++;
        },
        editUser(user) {
            router.push({
                name: 'edituser',
                params: {
                    userid: user.id,
                },
            });
        },
        deleteUser(user) {
            this.popup.active = true;
            this.popup.title = 'Delete ' + user.first_name + ' ' + user.last_name;
            this.popup.text = 'Are you sure you want to delete this user?';
            this.popup.userid = user.id;
        },
        async confirm() {
            const userManagement = new UserManagement();
            await userManagement.deleteUser(this.popup.userid);
            this.popup.active = false;
            await this.getAllUsers();
        },
        getGroupNames(groups) {
            let groupsNameRepresentation = '';
            for (let i = 0; i < groups.length; i++) {
                groupsNameRepresentation += groups[i].name;
                if (i < groups.length - 1) {
                    groupsNameRepresentation += ', ';
                }
            }
            return groupsNameRepresentation;
        },
    },
    computed: {
        ...mapGetters(['userCan']),
    },
    beforeMount() {
        this.getAllUsers();
    },
};
</script>

<style>
.searchbar {
    display: grid;
    grid-template-columns: minmax(200px, 9fr) 1fr;
    grid-column-gap: 35px;
}

.searchbar-no-create {
    display: grid;
    grid-template-columns: minmax(200px, 1fr);
    grid-column-gap: 35px;
}
</style>
