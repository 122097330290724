<template>
    <div v-if="hasErrors">
        <v-alert type="error" v-for="(error, index) in errors" :key="index">{{ error }}</v-alert>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['hasErrors', 'errors']),
    },
};
</script>
