<template>
    <v-form v-model="valid" ref="placeholdersForm">
        <v-data-table
            :headers="placeholdersHeaders"
            :items="placeholders"
            disable-pagination
            hide-default-footer
            fixed-header
            class="mb-5"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.name }}</td>
                    <td>
                        <v-text-field v-model="item.label" :rules="labelRules"></v-text-field>
                    </td>
                    <td>
                        <v-select
                            :items="possibleDataTypes"
                            item-text="label"
                            item-value="value"
                            v-model="item.type"
                            @change="changedDataType(item)"
                        ></v-select>
                    </td>
                    <td>
                        <v-row v-if="item.type === 'number'">
                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    type="number"
                                    label="Minimum"
                                    v-model="item.meta_data.minimum"
                                    :rules="numberRules"
                                    step="1"
                                ></v-text-field>
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    type="number"
                                    label="Maximum"
                                    v-model="item.meta_data.maximum"
                                    :rules="numberRules"
                                    step="1"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="item.type === 'decimal_number'">
                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    type="number"
                                    label="Minimum"
                                    v-model="item.meta_data.minimum"
                                    :rules="numberRules"
                                    step="0.1"
                                ></v-text-field>
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    type="number"
                                    label="Maximum"
                                    v-model="item.meta_data.maximum"
                                    :rules="numberRules"
                                    step="0.1"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="item.type === 'currency'">
                            <v-col class="pt-0 pb-0" cols="4">
                                <v-select
                                    :items="possibleCurrencies"
                                    label="Default currency"
                                    v-model="item.meta_data.currency_format"
                                    :rules="currencyRules"
                                ></v-select>
                            </v-col>
                            <v-col class="pt-0 pb-0" cols="4">
                                <v-text-field
                                    type="number"
                                    label="Minimum"
                                    v-model="item.meta_data.minimum"
                                    :rules="numberRules"
                                    step="0.01"
                                ></v-text-field>
                            </v-col>
                            <v-col class="pt-0 pb-0" cols="4">
                                <v-text-field
                                    type="number"
                                    label="Maximum"
                                    v-model="item.meta_data.maximum"
                                    :rules="numberRules"
                                    step="0.01"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="item.type === 'date'">
                            <v-col class="pt-0 pb-0">
                                <v-select
                                    :items="possibleDateFormats"
                                    :rules="selectorRules"
                                    label="Date format"
                                    item-text="label"
                                    item-value="value"
                                    v-model="item.meta_data.date_format"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="item.type === 'choice_list'">
                            <v-col class="pt-0 pb-0">
                                <v-combobox
                                    :rules="optionRules"
                                    allow-overflow
                                    multiple
                                    small-chips
                                    v-model="item.meta_data.choices"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="
                                item.type === 'person_contact' &&
                                contactDetails.includePersonContact
                            "
                        >
                            <v-col class="pt-0 pb-0">
                                <v-select
                                    :items="personContactFields"
                                    :rules="selectorRules"
                                    label="Person contact field"
                                    item-text="label"
                                    item-value="value"
                                    v-model="item.meta_data.person_contact_attribute_name"
                                ></v-select>
                            </v-col>
                            <v-col
                                class="pt-0 pb-0"
                                v-if="
                                    item.meta_data.person_contact_attribute_name == 'date_of_birth'
                                "
                            >
                                <v-select
                                    :items="possibleDateFormats"
                                    :rules="selectorRules"
                                    label="Date format"
                                    item-text="label"
                                    item-value="value"
                                    v-model="item.meta_data.date_format"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="
                                item.type === 'company_contact' &&
                                contactDetails.includeCompanyContact
                            "
                        >
                            <v-col class="pt-0 pb-0">
                                <v-select
                                    :items="companyContactFields"
                                    :rules="selectorRules"
                                    label="Company contact field"
                                    item-text="label"
                                    item-value="value"
                                    v-model="item.meta_data.company_contact_attribute_name"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-alert dense v-if="personContactFieldNotProvided" type="warning">
            You have not provided any person contact fields yet
        </v-alert>
        <v-alert dense v-if="companyContactFieldNotProvided" type="warning">
            You have not provided any company contact fields yet
        </v-alert>
        <v-btn class="mb-2" color="accent" @click="submit">Continue</v-btn>
    </v-form>
</template>

<script>
import {
    labelRules,
    numberRules,
    selectorRules,
    currencyRules,
    optionRules,
} from '@/utils/FieldValidations';
import {
    possibleCurrencies,
    possibleDateFormats,
    personContactFields,
    companyContactFields,
} from '@/utils/Constants';

export default {
    name: 'Step4Placeholder',
    emits: ['submit'],
    props: {
        value: {
            type: Array,
        },
        contactDetails: {
            type: Object,
        },
    },
    created() {
        this.templateElements = this.value;
    },
    computed: {
        possibleDataTypes() {
            const datatypes = [
                { label: 'Text', value: 'text' },
                { label: 'Date', value: 'date' },
                { label: 'Decimal number', value: 'decimal_number' },
                { label: 'Number', value: 'number' },
                { label: 'Choice list', value: 'choice_list' },
                { label: 'Currency', value: 'currency' },
            ];
            if (this.contactDetails.includePersonContact) {
                datatypes.push({ label: 'Person contact', value: 'person_contact' });
            }
            if (this.contactDetails.includeCompanyContact) {
                datatypes.push({ label: 'Company contact', value: 'company_contact' });
            }
            return datatypes;
        },
        placeholders() {
            const placeholders = [];
            for (let element of this.templateElements) {
                if (element.type === 'optional_section') continue;

                if (
                    element.type === 'number' ||
                    element.type === 'decimal_number' ||
                    element.type === 'currency'
                ) {
                    element.meta_data.minimum =
                        element.meta_data.minimum !== '' ? element.meta_data.minimum : null;
                    element.meta_data.maximum =
                        element.meta_data.maximum !== '' ? element.meta_data.maximum : null;
                }

                placeholders.push(element);
            }
            return placeholders;
        },

        personContactFieldNotProvided() {
            return (
                this.contactDetails.includePersonContact &&
                !this.templateElements
                    .map((templateElement) => templateElement.type)
                    .includes('person_contact')
            );
        },

        companyContactFieldNotProvided() {
            return (
                this.contactDetails.includeCompanyContact &&
                !this.templateElements
                    .map((templateElement) => templateElement.type)
                    .includes('company_contact')
            );
        },
    },
    watch: {
        templateElements: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.templateElements = value;
        },
    },
    data: () => ({
        templateElements: [],
        valid: true,

        labelRules: labelRules,
        numberRules: numberRules,
        selectorRules: selectorRules,
        currencyRules: currencyRules,
        optionRules: optionRules,

        possibleCurrencies: possibleCurrencies,
        possibleDateFormats: possibleDateFormats,
        personContactFields: personContactFields,
        companyContactFields: companyContactFields,

        placeholdersHeaders: [
            {
                text: 'Detected variable',
                value: 'name',
                sortable: false,
                width: '20%',
            },
            {
                text: 'Label name',
                value: 'label',
                sortable: false,
                width: '20%',
            },
            {
                text: 'Data type',
                value: 'type',
                sortable: false,
                width: '15%',
            },
            {
                text: 'Constraint',
                sortable: false,
                width: '45%',
            },
        ],
    }),
    methods: {
        changedDataType(item) {
            if (item.type === 'text') {
                item.meta_data = {};
            }
            if (item.type === 'choice_list') {
                item.meta_data = {
                    choices: null,
                };
            }
            if (item.type === 'decimal_number' || item.type === 'number') {
                item.meta_data = {
                    minimum: null,
                    maximum: null,
                };
            }
            if (item.type === 'currency') {
                item.meta_data = {
                    currency_format: null,
                    minimum: null,
                    maximum: null,
                };
            }
            if (item.type === 'date') {
                item.meta_data = {
                    date_format: null,
                };
            }
            if (item.type === 'person_contact') {
                item.meta_data = {
                    person_contact_attribute_name: null,
                };
            }
            if (item.type === 'company_contact') {
                item.meta_data = {
                    company_contact_attribute_name: null,
                };
            }
        },

        async submit() {
            await this.$refs.placeholdersForm.validate();
            if (!this.valid) return;
            this.$emit('submit');
        },
    },
};
</script>
