export const possibleCurrencies = ['EUR', 'USD', 'PKR'];

export const possibleDateFormats = [
    { label: 'D month YYYY', value: 'd MMMM yyyy' },
    { label: 'DD month YYYY', value: 'dd MMMM yyyy' },

    { label: 'DD-MM-YY', value: 'dd-MM-yy' },
    { label: 'MM-DD-YY', value: 'MM-dd-yy' },
    { label: 'YY-MM-DD', value: 'yy-MM-dd' },

    { label: 'DD/MM/YY', value: 'dd/MM/yy' },
    { label: 'MM/DD/YY', value: 'MM/dd/yy' },
    { label: 'YY/MM/DD', value: 'yy/MM/dd' },

    { label: 'DD-MM-YYYY', value: 'dd-MM-yyyy' },
    { label: 'MM-DD-YYYY', value: 'MM-dd-yyyy' },
    { label: 'YYYY-MM-DD', value: 'yyyy-MM-dd' },

    { label: 'DD/MM/YYYY', value: 'dd/MM/yyyy' },
    { label: 'MM/DD/YYYY', value: 'MM/dd/yyyy' },
    { label: 'YYYY/MM/DD', value: 'yyyy/MM/dd' },
];

export const personContactFields = [
    { label: 'Initials', value: 'initials' },
    { label: 'Full name', value: 'full_name' },
    { label: 'First name', value: 'first_name' },
    { label: 'Last name', value: 'last_name' },
    { label: 'City', value: 'city' },
    { label: 'Street', value: 'street' },
    { label: 'House number', value: 'house_number' },
    { label: 'Postal code', value: 'postal_code' },
    { label: 'BSN', value: 'bsn' },
    { label: 'Place of birth', value: 'place_of_birth' },
    { label: 'Date of birth', value: 'date_of_birth' },
    { label: 'Nationality', value: 'nationality' },
    { label: 'Identification type', value: 'identification_type' },
    { label: 'Document number passport/ID', value: 'document_number' },
    { label: 'Place of issue passport/ID', value: 'place_of_issue_id' },
    { label: 'Date of issue passport/ID', value: 'date_of_issue_id' },
    { label: 'Date of validity passport/ID', value: 'date_of_validity_id' },
    { label: 'Position', value: 'position' },
];

export const companyContactFields = [
    { label: 'Name', value: 'name' },
    { label: 'Street', value: 'street' },
    { label: 'House number', value: 'house_number' },
    { label: 'Postal code', value: 'postal_code' },
    { label: 'City', value: 'city' },
    { label: 'First name', value: 'first_name' },
    { label: 'Last name', value: 'last_name' },
    { label: 'KVK', value: 'kvk' },
    { label: 'Position', value: 'position' },
];
