<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="new-company-contact elevation-5" min-width="400px">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Edit Company</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row class="ml-4 mr-4">
                        <v-col>
                            <template v-for="(_, propertyName, index) in companyContact">
                                <v-text-field
                                    v-if="index < 5"
                                    :key="index"
                                    v-model="companyContact[propertyName]"
                                    :label="formatPropertyName(propertyName)"
                                ></v-text-field>
                            </template>
                        </v-col>
                        <v-col>
                            <template v-for="(_, propertyName, index) in companyContact">
                                <v-text-field
                                    v-if="index >= 5"
                                    :key="index"
                                    v-model="companyContact[propertyName]"
                                    :label="formatPropertyName(propertyName)"
                                ></v-text-field>
                            </template>
                        </v-col>
                    </v-row>
                    <div class="text-h5 ml-7 mt-7 mb-5">Access</div>
                    <v-row class="ml-4 mr-4">
                        <v-col>
                            <groups-selector v-model="groups" />
                        </v-col>
                        <v-col>
                            <users-selector v-model="users" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="ml-4 mr-4">
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :to="{ name: 'company.contacts' }" class="mr-5 mb-3">
                        Cancel
                    </v-btn>
                    <v-btn color="accent" @click="editCompanyContact()" class="mr-3 mb-3">
                        Save
                    </v-btn>
                </v-card-actions>
                <empty-fields-dialog
                    button-text="Save"
                    :button-callback="editCompanyContact"
                    v-model="showEmptyFieldsWarning"
                />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import router from '@/router';
import { upperFirst } from 'lodash';
import ContactsApi from '@/api/Contacts/ContactsApi';
import GroupsSelector from '@/components/Selectors/GroupsSelector';
import UsersSelector from '@/components/Selectors/UsersSelector';
import EmptyFieldsDialog from '@/components/Contacts/EmptyFieldsWarning';

export default {
    components: { EmptyFieldsDialog, UsersSelector, GroupsSelector },
    data() {
        return {
            valid: false,
            companyContact: {
                name: '',
                city: '',
                street: '',
                house_number: '',
                postal_code: '',
                kvk: '',
                first_name: '',
                last_name: '',
                position: '',
            },
            users: [this.$store.getters.user.id],
            groups: [],
            showEmptyFieldsWarning: false,
            companyContactId: '',
        };
    },
    async created() {
        const contactsApi = new ContactsApi();
        this.companyContactId = this.$route.params.id;
        const responseData = await contactsApi.getSpecificCompanyContact(this.companyContactId);
        this.companyContact = {
            name: responseData.name,
            city: responseData.city,
            street: responseData.street,
            house_number: responseData.house_number,
            postal_code: responseData.postal_code,
            kvk: responseData.kvk,
            first_name: responseData.first_name,
            last_name: responseData.last_name,
            position: responseData.position,
        };
        this.users = responseData.users;
        this.groups = responseData.groups;
        this.loaded = true;
    },
    methods: {
        async editCompanyContact() {
            if (this.hasEmptyFields()) {
                this.showEmptyFieldsWarning = true;
                return;
            }

            const companyContactRequestData = this.companyContact;
            companyContactRequestData['users'] = this.users;
            companyContactRequestData['groups'] = this.groups;

            const contactsApi = new ContactsApi();
            const succeeded = await contactsApi.createCompanyContact(companyContactRequestData);

            if (succeeded) {
                await router.push({ name: 'company.contacts' });
            }
        },

        formatPropertyName(fieldName) {
            return upperFirst(fieldName.replace(/_/g, ' '));
        },

        hasEmptyFields() {
            for (const field in this.companyContact) {
                if (this.companyContact[field] === '') {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
