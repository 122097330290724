import ApiConsumer from '../ApiConsumer';

export default class GroupManagement extends ApiConsumer {
    /**
     * Retrieves all groups
     */
    async getGroups() {
        try {
            const response = await this.api.get('/groups/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Retrieves a group by their group id
     */
    async getSpecificGroup(groupId) {
        try {
            const response = await this.api.get('/groups/' + groupId + '/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Creates a specific group
     */
    async createGroup(group) {
        try {
            const response = await this.api.post('/groups/', group);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Edits a specific group
     */
    async editedGroup(editedGroup, id) {
        try {
            const response = await this.api.put('/groups/' + id + '/', editedGroup);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Deletes a specific group
     */
    async deleteGroup(id) {
        try {
            const response = await this.api.delete('/groups/' + id + '/');
            return response.data;
        } catch (error) {
            return false;
        }
    }
}
