<template>
    <div>
        <v-file-input
            class="mt-2"
            outlined
            dense
            accept=".doc, .docx"
            label="Select a word file"
            v-model="templateFile"
        ></v-file-input>
        <v-btn
            class="mb-2"
            :disabled="templateFile == null"
            color="accent"
            @click="detectTemplateElements"
            >Confirm</v-btn
        >
        <v-dialog v-model="errorDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Error</span>
                </v-card-title>
                <v-card-text class="pb-0"> The word document you uploaded is invalid. </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        dark
                        color="secondary"
                        class="elevation-3 ma-3"
                        @click="errorDialog = false"
                        >OK</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TemplatingEngine from '@/api/TemplatesModule/TemplatingEngine';

export default {
    name: 'Step1UploadWordFile',
    emits: ['submit'],
    data: () => ({
        templateFile: null,
        errorDialog: false,
        loading: false,
    }),
    methods: {
        async detectTemplateElements() {
            this.loading = true;
            // Given the Word file, detect the optional sections and placeholders.
            const templatingEngine = new TemplatingEngine();
            const json = await templatingEngine.detect(this.templateFile);

            if (json === false) {
                this.errorDialog = true;
                this.loading = false;
                return;
            }

            const templateElements = [];
            for (const element of json.elements) {
                if (element.type === 'placeholder') {
                    templateElements.push({
                        name: element.name,
                        type: 'text',
                        label: element.name,
                        meta_data: {},
                    });
                } else if (element.type === 'optional_section') {
                    templateElements.push({
                        name: element.name,
                        type: element.type,
                        label: element.name,
                        default: false,
                    });
                }
            }

            this.loading = false;

            this.$emit('submit', {
                templateElements: templateElements,
                wordDocumentId: json.word_doc_id,
            });
        },
    },
};
</script>
