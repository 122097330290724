<template>
    <v-row class="align-self-start">
        <v-col>
            <document-constructor :documentId="documentId" @submit="editDocument" />
            <v-overlay :value="loadingState">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-col>
    </v-row>
</template>

<script>
import DocumentManagement from '@/api/TemplatesModule/DocumentManagement';
import router from '@/router';
import DocumentConstructor from './DocumentConstructor.vue';

export default {
    components: { DocumentConstructor },
    data: () => ({
        loadingState: false,
    }),
    computed: {
        documentId() {
            return this.$route.params.documentid;
        },
    },
    methods: {
        async editDocument(document) {
            this.loadingState = true;

            const documentManagement = new DocumentManagement();
            await documentManagement.editDocument(document, this.documentId);

            this.loadingState = false;

            await router.push({ name: 'documents' });
        },
    },
};
</script>
