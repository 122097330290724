import { upperFirst } from 'lodash';

export const formatPropertyName = (fieldName) => {
    if (fieldName == "full_name") {
        return "Full name(s) (as per passport/ID)"
    }
    if (fieldName == "document_number") {
        return "Document number passport/ID"
    }
    fieldName = fieldName.replace(/_id/g, '_passport/ID')
    return upperFirst(fieldName.replace(/_/g, ' '));
}