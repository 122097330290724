<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="new-user-card elevation-5" min-width="400px">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>New User</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-form ref="form" v-model="valid" lazy-validation class="ml-5 mr-5">
                                <v-text-field
                                    v-model="newFirstName"
                                    label="First name"
                                    :rules="nameRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model="newLastName"
                                    label="Last name"
                                    :rules="nameRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model="newUserEmail"
                                    label="E-mail"
                                    :rules="emailRules()"
                                ></v-text-field>
                                <v-text-field
                                    label="Password"
                                    v-model="newUserPassword"
                                    :rules="passwordRules"
                                    type="password"
                                ></v-text-field>
                                <v-text-field
                                    label="Confirm password"
                                    :rules="passwordConfirmRules()"
                                    v-model="newUserPasswordConfirm"
                                    type="password"
                                ></v-text-field>
                                <v-select
                                    v-model="role"
                                    :rules="roleRules()"
                                    :items="possibleRoles"
                                    item-text="name"
                                    item-value="id"
                                    label="Select role"
                                ></v-select>
                            </v-form>
                        </v-col>
                        <v-col>
                            <v-data-table
                                height="260px"
                                :headers="groupsHeaders"
                                :items="groupsContent"
                                :search="searchGroups"
                                v-model="selectedGroups"
                                item-key="id"
                                disable-pagination
                                hide-default-footer
                                fixed-header
                                show-select
                                class="mr-3"
                                mobile-breakpoint="0"
                            >
                                <template v-slot:top>
                                    <v-text-field
                                        v-model="searchGroups"
                                        append-icon="mdi-magnify"
                                        label="Search group"
                                        single-line
                                        hide-details
                                        class="mb-5"
                                    ></v-text-field>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :to="{ name: 'usermanagement' }" class="mr-5 mb-3"
                        >Cancel</v-btn
                    >
                    <v-btn color="accent" @click="saveNewUser()" class="mr-3 mb-3">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import router from '@/router';
import UserManagement from '@/api/AccessManagement/UserManagement';
import RoleManagement from '@/api/AccessManagement/RoleManagement';
import GroupManagement from '@/api/AccessManagement/GroupManagement';

export default {
    data: () => ({
        valid: false,
        newFirstName: null,
        newLastName: null,
        newUserEmail: null,
        newUserPassword: null,
        newUserPasswordConfirm: null,
        role: null,
        possibleRoles: [],
        searchGroups: null,
        groupsHeaders: [
            { text: 'Group', sortable: false, value: 'name' },
            { text: 'Users', sortable: false, value: 'users' },
        ],

        nameRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
        ],
        uniqueEmail: true,
        passwordRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.length >= 8) || 'This field must be at least 8 characters',
            (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
        ],
        groupsContent: [],
        selectedGroups: [],
    }),
    watch: {
        newUserEmail: function () {
            this.uniqueEmail = true;
        },
    },
    methods: {
        async saveNewUser() {
            await this.$refs.form.validate();
            if (!this.valid) return;
            const userManagement = new UserManagement();
            const response = await userManagement.createUser({
                first_name: this.newFirstName,
                last_name: this.newLastName,
                email: this.newUserEmail,
                password: this.newUserPassword,
                groups: this.selectedGroups.map((group) => group.id),
                role: this.role,
            });
            if (response === false) {
                this.uniqueEmail = false;
                await this.$refs.form.validate();
            } else {
                await router.push({ name: 'usermanagement' });
            }
        },

        passwordConfirmRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) =>
                    (v && v === this.newUserPassword) || 'This field must match the password field',
            ];
        },
        emailRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
                (v) =>
                    (v &&
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            String(v).toLowerCase()
                        )) ||
                    'This must be a valid email address',
                this.uniqueEmail || 'A user with this email already exists',
            ];
        },
        roleRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) =>
                    (v && this.possibleRoles.map((r) => r.id).includes(v)) || 'Select a valid role',
            ];
        },
    },
    async created() {
        const roleManagement = new RoleManagement();
        this.possibleRoles = await roleManagement.getRoles();
        const groupManagement = new GroupManagement();
        this.groupsContent = await groupManagement.getGroups();
    },
};
</script>
