import ApiConsumer from '../ApiConsumer';

export default class ContactsApi extends ApiConsumer {
    async getPersonContacts() {
        try {
            const response = await this.api.get('/person-contacts/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    async getSpecificPersonContact(personContactId) {
        try {
            const response = await this.api.get(`/person-contacts/${personContactId}/`);
            return response.data;
        } catch (error) {
            return [];
        }
    }

    async createPersonContact(personContact) {
        try {
            const response = await this.api.post('/person-contacts/', personContact);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async editPersonContact(editedPersonContact, personContactId) {
        try {
            const response = await this.api.patch(
                `/person-contacts/${personContactId}/`,
                editedPersonContact
            );
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async deletePersonContact(personContactId) {
        try {
            const response = await this.api.delete(`/person-contacts/${personContactId}/`);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async getCompanyContacts() {
        try {
            const response = await this.api.get('/company-contacts/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    async getSpecificCompanyContact(companyContactId) {
        try {
            const response = await this.api.get(`/company-contacts/${companyContactId}/`);
            return response.data;
        } catch (error) {
            return [];
        }
    }

    async createCompanyContact(companyContact) {
        try {
            const response = await this.api.post('/company-contacts/', companyContact);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async editCompanyContact(editedCompanyContact, companyContactId) {
        try {
            const response = await this.api.patch(
                `/company-contacts/${companyContactId}/`,
                editedCompanyContact
            );
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async deleteCompanyContact(companyContactId) {
        try {
            const response = await this.api.delete(`/company-contacts/${companyContactId}/`);
            return response.data;
        } catch (error) {
            return false;
        }
    }
}
