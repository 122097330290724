<template>
    <v-menu
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="date"
                :label="label"
            ></v-text-field>
        </template>
        <v-date-picker v-model="date" no-title scrollable></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    name: 'DateInputField',
    data() {
        return {
            date: '',
        };
    },
    created() {
        this.date = this.value;
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
        },
    },
    watch: {
        date(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style scoped></style>
