<template>
    <v-data-table
        height="260px"
        :headers="usersHeaders"
        :items="users"
        :search="searchQuery"
        v-model="model"
        item-key="id"
        disable-pagination
        hide-default-footer
        fixed-header
        show-select
        class="mr-3"
        mobile-breakpoint="0"
    >
        <template v-slot:top>
            <v-text-field
                v-model="searchQuery"
                append-icon="mdi-magnify"
                label="Search user"
                single-line
                hide-details
                class="mb-5"
            ></v-text-field>
        </template>
    </v-data-table>
</template>

<script>
import UserManagement from '@/api/AccessManagement/UserManagement';

export default {
    name: 'UsersSelector',
    data() {
        return {
            usersHeaders: [
                { text: 'Name', sortable: false, value: 'name' },
                { text: 'Email', sortable: false, value: 'email' },
            ],
            searchQuery: '',
            users: [],
            model: [],
        };
    },

    props: {
        /**
         * Array of user objects.
         */
        value: {
            type: Array,
            required: true,
        },
    },

    watch: {
        model() {
            this.$emit(
                'input',
                this.model.map((u) => u.id)
            );
        },
    },

    async created() {
        const userManagement = new UserManagement();
        this.users = await userManagement.getUsers();
        this.model = this.users.filter((u) => this.value.includes(u.id));
    },
};
</script>

<style scoped></style>
