<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="new-group-card elevation-5">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Edit Role</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <Errors />
                    <v-form ref="form" v-model="valid">
                        <v-text-field
                            class="ml-4 mr-4"
                            v-model="roleName"
                            label="Role name"
                            :rules="roleRules"
                        ></v-text-field>
                    </v-form>
                    <v-data-table
                        height="500px"
                        :headers="permissionHeaders"
                        :items="permissionContent"
                        :search="searchRoles"
                        disable-pagination
                        hide-default-footer
                        fixed-header
                        dense
                        class="mt-4 ml-4 mr-4"
                        mobile-breakpoint="0"
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.permissionCategory }}</td>
                                <td>
                                    <v-checkbox
                                        color="black"
                                        :disabled="
                                            retrievePermissionCategory(
                                                disabledPermissions,
                                                item.permissionCategory
                                            ).create
                                        "
                                        v-model="item.create"
                                    ></v-checkbox>
                                </td>
                                <td>
                                    <v-checkbox
                                        color="black"
                                        :disabled="
                                            retrievePermissionCategory(
                                                disabledPermissions,
                                                item.permissionCategory
                                            ).read
                                        "
                                        v-model="item.read"
                                    ></v-checkbox>
                                </td>
                                <td>
                                    <v-checkbox
                                        color="black"
                                        :disabled="
                                            retrievePermissionCategory(
                                                disabledPermissions,
                                                item.permissionCategory
                                            ).update
                                        "
                                        v-model="item.update"
                                    ></v-checkbox>
                                </td>
                                <td>
                                    <v-checkbox
                                        color="black"
                                        :disabled="
                                            retrievePermissionCategory(
                                                disabledPermissions,
                                                item.permissionCategory
                                            ).delete
                                        "
                                        v-model="item.delete"
                                    ></v-checkbox>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :to="{ name: 'rolemanagement' }" class="mr-5 mb-3"
                        >Cancel</v-btn
                    >
                    <v-btn color="accent" @click="saveEditedRole()" class="mr-3 mb-3">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapMutations } from 'vuex';
import router from '@/router';
import Errors from '@/components/Errors';
import RoleManagement from '@/api/AccessManagement/RoleManagement';

export default {
    data: () => ({
        valid: false,
        roleName: null,
        searchRoles: '',
        permissionHeaders: [
            { text: 'Permission', sortable: false },
            { text: 'Create', sortable: false },
            { text: 'View', sortable: false },
            { text: 'Edit', sortable: false },
            { text: 'Delete', sortable: false },
        ],
        permissionContent: [
            {
                permissionCategory: 'Templates',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Documents',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Users',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Groups',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Roles',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Contacts',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
        ],
        disabledPermissions: [
            {
                permissionCategory: 'Templates',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Documents',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Users',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Groups',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Roles',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
            {
                permissionCategory: 'Contacts',
                create: false,
                read: false,
                update: false,
                delete: false,
            },
        ],
        roleNames: [],
        originalRoleName: null,
    }),
    components: {
        Errors,
    },
    computed: {
        roleRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
                (v) =>
                    (v && (!this.roleNames.includes(v.trim()) || this.originalRoleName === v)) ||
                    'This role name already exists',
            ];
        },
    },
    watch: {
        //Forces relations between permissions
        permissionContent: {
            handler(permissions) {
                const templates = this.retrievePermissionCategory(permissions, 'Templates');
                const documents = this.retrievePermissionCategory(permissions, 'Documents');
                const users = this.retrievePermissionCategory(permissions, 'Users');
                const groups = this.retrievePermissionCategory(permissions, 'Groups');
                const roles = this.retrievePermissionCategory(permissions, 'Roles');
                const contacts = this.retrievePermissionCategory(permissions, 'Contacts');

                const templates_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Templates'
                );
                const users_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Users'
                );
                const groups_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Groups'
                );
                const roles_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Roles'
                );
                const contacts_disabled = this.retrievePermissionCategory(
                    this.disabledPermissions,
                    'Contacts'
                );

                if (!templates.create && !templates.update && !groups.create) {
                    users_disabled.read = false;
                }
                if (!templates.create && !templates.update && !users.update && !users.create) {
                    groups_disabled.read = false;
                }
                if (!users.update && !users.create) {
                    roles_disabled.read = false;
                }
                if (!documents.update && !documents.create) {
                    templates_disabled.read = false;
                    contacts_disabled.read = false;
                }

                //Cannot create/edit template when only Create/UpdateTemplate is enabled -> need ReadUsers and ReadGroups
                if (templates.create || templates.update) {
                    users.read = true;
                    groups.read = true;
                    users_disabled.read = true;
                    groups_disabled.read = true;
                }
                //Cannot edit or create document when only CreateDocuments UpdateDocuments is enabled -> need ReadTemplates and ReadContacts also
                if (documents.update || documents.create) {
                    templates.read = true;
                    templates_disabled.read = true;
                    contacts.read = true;
                    contacts_disabled.read = true;
                }
                //Cannot edit/create users when only UpdateUsers is enabled -> need ReadRoles and ReadGroups also
                if (users.update || users.create) {
                    groups.read = true;
                    roles.read = true;
                    groups_disabled.read = true;
                    roles_disabled.read = true;
                }
                //Cannot create group when only CreateGroup is enabled -> need ReadUsers also
                if (groups.create) {
                    users.read = true;
                    users_disabled.read = true;
                }
                if (contacts.create || contacts.update) {
                    users.read = true;
                    groups.read = true;
                    users_disabled.read = true;
                    groups_disabled.read = true;
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapMutations(['addError', 'resetErrors']),
        async saveEditedRole() {
            this.resetErrors();
            await this.$refs.form.validate();
            if (!this.valid) return;
            if (this.getPermissions().length <= 0) {
                this.addError('Select at least one permission');
                return;
            }
            const roleManagement = new RoleManagement();
            await roleManagement.editRole(
                {
                    id: this.$route.params.roleid,
                    name: this.roleName.trim(),
                    permissions: this.getPermissions(),
                },
                this.$route.params.roleid
            );
            await router.push({ name: 'rolemanagement' });
        },
        getPermissions() {
            const permissions = [];
            for (const permission of this.permissionContent) {
                if (permission.create) permissions.push('Create' + permission.permissionCategory);
                if (permission.read) permissions.push('Read' + permission.permissionCategory);
                if (permission.update) permissions.push('Update' + permission.permissionCategory);
                if (permission.delete) permissions.push('Delete' + permission.permissionCategory);
            }
            return permissions;
        },
        retrievePermissionCategory(permissions, category) {
            for (const permission of permissions)
                if (permission.permissionCategory === category) return permission;
        },
    },
    async created() {
        const roleManagement = new RoleManagement();

        const permissionRole = await roleManagement.getSpecificRole(this.$route.params.roleid);
        const permissions = permissionRole.permissions.map((permission) => permission.name);
        this.roleName = permissionRole.name;
        this.originalRoleName = permissionRole.name;

        //Retrieve the names of all roles
        const allRoles = await roleManagement.getRoles();
        const names = [];
        for (const role of allRoles) names.push(role.name);
        this.roleNames = names;

        const templates = this.retrievePermissionCategory(this.permissionContent, 'Templates');
        const documents = this.retrievePermissionCategory(this.permissionContent, 'Documents');
        const users = this.retrievePermissionCategory(this.permissionContent, 'Users');
        const groups = this.retrievePermissionCategory(this.permissionContent, 'Groups');
        const roles = this.retrievePermissionCategory(this.permissionContent, 'Roles');
        const contacts = this.retrievePermissionCategory(this.permissionContent, 'Contacts');
        templates.create = permissions.indexOf('CreateTemplates') > -1;
        templates.read = permissions.indexOf('ReadTemplates') > -1;
        templates.update = permissions.indexOf('UpdateTemplates') > -1;
        templates.delete = permissions.indexOf('DeleteTemplates') > -1;
        documents.create = permissions.indexOf('CreateDocuments') > -1;
        documents.read = permissions.indexOf('ReadDocuments') > -1;
        documents.update = permissions.indexOf('UpdateDocuments') > -1;
        documents.delete = permissions.indexOf('DeleteDocuments') > -1;
        users.create = permissions.indexOf('CreateUsers') > -1;
        users.read = permissions.indexOf('ReadUsers') > -1;
        users.update = permissions.indexOf('UpdateUsers') > -1;
        users.delete = permissions.indexOf('DeleteUsers') > -1;
        groups.create = permissions.indexOf('CreateGroups') > -1;
        groups.read = permissions.indexOf('ReadGroups') > -1;
        groups.update = permissions.indexOf('UpdateGroups') > -1;
        groups.delete = permissions.indexOf('DeleteGroups') > -1;
        roles.create = permissions.indexOf('CreateRoles') > -1;
        roles.read = permissions.indexOf('ReadRoles') > -1;
        roles.update = permissions.indexOf('UpdateRoles') > -1;
        roles.delete = permissions.indexOf('DeleteRoles') > -1;
        contacts.create = permissions.indexOf('CreateContacts') > -1;
        contacts.read = permissions.indexOf('ReadContacts') > -1;
        contacts.update = permissions.indexOf('UpdateContacts') > -1;
        contacts.delete = permissions.indexOf('DeleteContacts') > -1;
        if (this.$store.getters.user.role.name === this.roleName) {
            const roles_disabled = this.retrievePermissionCategory(
                this.disabledPermissions,
                'Roles'
            );
            roles_disabled.read = true;
            roles_disabled.update = true;
        }
    },
};
</script>
