<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card>
                <v-tabs dark value="/access-management" color="white" background-color="primary">
                    <v-tab :to="{ name: 'usermanagement' }" v-if="userCan('ReadUsers')"
                        >Users</v-tab
                    >
                    <v-tab :to="{ name: 'groupmanagement' }" v-if="userCan('ReadGroups')"
                        >Groups</v-tab
                    >
                    <v-tab :to="{ name: 'rolemanagement' }" v-if="userCan('ReadRoles')"
                        >Roles</v-tab
                    >

                    <v-tab-item value="/access-management/users">
                        <router-view
                            v-if="$router.currentRoute.path === '/access-management/users'"
                        ></router-view>
                    </v-tab-item>

                    <v-tab-item value="/access-management/groups">
                        <router-view
                            v-if="$router.currentRoute.path === '/access-management/groups'"
                        ></router-view>
                    </v-tab-item>

                    <v-tab-item value="/access-management/roles">
                        <router-view
                            v-if="$router.currentRoute.path === '/access-management/roles'"
                        ></router-view>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['userCan']),
    },
};
</script>
