import ApiConsumer from '../ApiConsumer';

export default class DocumentManagement extends ApiConsumer {
    /**
     * Creates a document
     * @param document document to create.
     */
    async createDocument(document) {
        try {
            const response = await this.api.post('/documents/', document);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async delete(id) {
        try {
            await this.api.delete('/documents/' + id + '/');
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Retrieves all documents
     */
    async getAllDocuments() {
        try {
            const response = await this.api.get('/documents/');
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Retrieves a rendered pdf
     * @param targetDocument document to render.
     * @param pdf boolean value indicating if we want a pdf (true) or word (false) file.
     */
    async getRenderedDocument(targetDocument, pdf) {
        try {
            const route = pdf ? 'pdf' : 'word';
            await this.api
                .get('/render-document/' + route + '/' + targetDocument.id + '/', {
                    responseType: 'blob',
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const fileExtension = pdf ? '.pdf' : '.docx';
                    link.setAttribute('download', targetDocument.name + fileExtension);
                    document.body.appendChild(link);
                    link.click();
                });
        } catch (error) {
            return false;
        }
    }

    /**
     * Retrieves a specific document
     */
    async getSpecificDocument(documentid) {
        try {
            const response = await this.api.get('/documents/' + documentid + '/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Retrieves a specific template
     * @param templateid uuid of the template
     */
    async getSpecificDocumentMetaData(documentid) {
        try {
            const response = await this.api.get('/documents/' + documentid + '/meta-data/');
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Retrieves all templates with folder structure
     */
    async getDocumentsWithFolderStructure() {
        try {
            const response = await this.api.get('/document-folders/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Edits a template
     */
    async editDocument(editedDocument, id) {
        try {
            const response = await this.api.patch('/documents/' + id + '/', editedDocument);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async deleteDocument(documentid) {
        try {
            await this.api.delete('/documents/' + documentid + '/');
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Creates a folder
     * @param name folder name
     * @param parent uuid of parent
     */
    async createFolder(name, parent) {
        try {
            const response = await this.api.post('/document-folders/', {
                name: name,
                parent: parent,
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * edits a folder
     * @param id folder id
     * @param editedFolder edited attributes of the folder
     */
    async editFolder(id, editedFolder) {
        try {
            const response = await this.api.patch('/document-folders/' + id + '/', editedFolder);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Deletes a folder
     * @param folderid uuid of folder
     */
    async deleteFolder(folderid) {
        try {
            const response = await this.api.delete('/document-folders/' + folderid + '/');
            return response.data;
        } catch (error) {
            return false;
        }
    }
}
