var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"align-self-start"},[_c('v-col',[_c('v-data-table',{attrs:{"height":"500px","headers":_vm.usersHeaders,"items":_vm.usersContent,"search":_vm.searchUsers,"disable-pagination":"","hide-default-footer":"","fixed-header":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"ma-4",class:{
                        searchbar: _vm.userCan('CreateUsers'),
                        'searchbar-no-create': !_vm.userCan('CreateUsers'),
                    }},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchUsers),callback:function ($$v) {_vm.searchUsers=$$v},expression:"searchUsers"}}),(_vm.userCan('CreateUsers'))?_c('v-btn',{staticClass:"mt-3",attrs:{"dark":"","to":{ name: 'newuser' },"width":"150px","color":"accent"}},[_vm._v("New User")]):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [(_vm.userCan('UpdateUsers'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(
                        !(item.role_name === 'Admin' && _vm.numberOfAdminUsers < 2) &&
                        _vm.userCan('DeleteUsers')
                    )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.popup.active),callback:function ($$v) {_vm.$set(_vm.popup, "active", $$v)},expression:"popup.active"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.popup.title))])]),_c('v-card-text',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.popup.text))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"elevation-3 ma-4",attrs:{"dark":"","color":"secondary"},on:{"click":function($event){_vm.popup.active = false}}},[_vm._v("Cancel ")]),_c('v-btn',{staticClass:"elevation-3 ma-4",attrs:{"color":"error"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v("Delete")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }