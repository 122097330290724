<template>
    <v-card>
        <v-card-title>
            <span class="headline">New folder</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
                <v-text-field
                    v-model="newFolderName"
                    label="Folder name"
                    @keyup.enter="createFolder"
                    :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
            </v-form>
            <div v-if="folderOnlyTreeStructure.length > 0">
                Select a folder in which you want to place the new folder (optional).
                <v-treeview
                    :items="folderOnlyTreeStructure"
                    item-key="id"
                    hoverable
                    rounded
                    transition
                    open-all
                    activatable
                    :active.sync="newFolderParent"
                >
                    <template v-slot:prepend="{ item, open }">
                        <v-icon
                            v-text="
                                `mdi-${open && item.children.length > 0 ? 'folder-open' : 'folder'}`
                            "
                        ></v-icon>
                    </template>
                    <template v-slot:label="{ item }">
                        <div v-if="item.type === 'folder'">{{ item.name }}</div>
                    </template>
                </v-treeview>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small dark color="accent" class="mr-4 mb-3" @click="createFolder">Create</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        treeStructure: Array,
    },
    data: () => ({
        //The name of the new folder
        newFolderName: null,
        //The parent of the new folder (first element of the array).
        newFolderParent: [null],
        //True if name is provided.
        valid: false,
    }),
    computed: {
        folderOnlyTreeStructure() {
            return this.getFoldersOnly(this.treeStructure);
        },
    },
    methods: {
        getFoldersOnly(root) {
            const folders = [];
            for (let i = 0; i < root.length; i++) {
                const item = root[i];
                if (item.type === 'folder') {
                    folders.push({
                        type: 'folder',
                        id: item.id,
                        name: item.name,
                        children: this.getFoldersOnly(item.children),
                    });
                }
            }
            return folders;
        },
        async createFolder() {
            await this.$refs.form.validate();
            if (!this.valid) return;
            this.$emit('create-folder', this.newFolderName, this.newFolderParent[0]);
            this.newFolderName = null;
            this.newFolderParent = [null];
        },
    },
};
</script>
