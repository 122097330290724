import Vue from 'vue';
import VueRouter from 'vue-router';
import errors from './middleware/errors';
import auth from './middleware/auth';
import capabilities from './middleware/capabilities';
import Home from '@/views/Home';
import UserManagementRoutes from '@/router/AccessManagement/UserManagementRoutes';
import AccountRoutes from '@/router/AccessManagement/AccountRoutes';
import GroupManagementRoutes from '@/router/AccessManagement/GroupManagementRoutes';
import MainAccessManagementRoutes from '@/router/AccessManagement/MainAccessManagementRoutes';
import RoleManagementRoutes from '@/router/AccessManagement/RoleManagementRoutes';
import DocumentRoutes from '@/router/TemplateModule/DocumentRoutes';
import TemplateRoutes from '@/router/TemplateModule/TemplateRoutes';
import ContactRoutes from '@/router/Contacts/ContactRoutes';

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {
                auth: true,
            },
        },
        { path: '*', redirect: { name: 'login' } },
        ...UserManagementRoutes,
        ...AccountRoutes,
        ...MainAccessManagementRoutes,
        ...GroupManagementRoutes,
        ...RoleManagementRoutes,
        ...UserManagementRoutes,
        ...DocumentRoutes,
        ...TemplateRoutes,
        ...ContactRoutes,
    ],
    mode: 'history',
});

router.beforeEach(errors);
router.beforeEach(auth);
router.beforeEach(capabilities);

export default router;
