<template>
    <v-navigation-drawer fixed permanent expand-on-hover dark color="primary">
        <v-list nav dense>
            <v-list-item
                link
                :to="{ name: 'account' }"
                v-if="user != null && !$store.getters.isSystemAdministrator"
            >
                <v-list-item-icon>
                    <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-1">{{ user.first_name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider v-if="user != null && !$store.getters.isSystemAdministrator"></v-divider>

            <v-list-item link :to="{ name: 'home' }">
                <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'templates' }" v-if="userCan('ReadTemplates')">
                <v-list-item-icon>
                    <v-icon>mdi-file-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Template registry</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="{ name: 'documents' }" v-if="userCan('ReadDocuments')">
                <v-list-item-icon>
                    <v-icon>mdi-file</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Document registry</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="{ name: 'person.contacts' }" v-if="userCan('ReadContacts')">
                <v-list-item-icon>
                    <v-icon>mdi-book-open-blank-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Contacts</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                link
                :to="{ name: 'accessmanagement' }"
                v-if="userCan('ReadUsers') || userCan('ReadGroups') || userCan('ReadRoles')"
            >
                <v-list-item-icon>
                    <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Access management</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                :to="{ name: 'companies' }"
                v-if="$store.getters.isSystemAdministrator"
            >
                <v-list-item-icon>
                    <v-icon>mdi-card-account-details</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Company management</v-list-item-title>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <v-list-item link @click="logout">
                <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions(['logout']),
    },
    computed: {
        ...mapGetters(['user', 'userCan']),
    },
};
</script>

<style></style>
