<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="new-person-contact elevation-5" min-width="400px">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Edit Person</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row class="ml-4 mr-4">
                        <v-col>
                            <template v-for="(_, propertyName, index) in personContact">
                                <v-text-field
                                    v-if="index < 9 && loaded"
                                    :key="index"
                                    v-model="personContact[propertyName]"
                                    :label="formatPropertyName(propertyName)"
                                ></v-text-field>
                            </template>
                        </v-col>
                        <v-col>
                            <template v-for="(_, propertyName, index) in personContact">
                                <date-input-field
                                    v-if="(propertyName === 'date_of_birth' || propertyName == 'date_of_issue_id' || propertyName == 'date_of_validity_id') && index >= 9 && loaded"
                                    :key="index"
                                    v-model="personContact[propertyName]"
                                    :label="formatPropertyName(propertyName)"
                                />
                                <v-select
                                    v-else-if="propertyName === 'identification_type' && index >= 9 && loaded"
                                    :key="index"
                                    :items="['Passport', 'ID']"
                                    v-model="personContact[propertyName]"
                                    :label="formatPropertyName(propertyName)"
                                />
                                <v-text-field
                                    v-else-if="index >= 9 && loaded"
                                    :key="index"
                                    v-model="personContact[propertyName]"
                                    :label="formatPropertyName(propertyName)"
                                ></v-text-field>
                            </template>
                        </v-col>
                    </v-row>
                    <div class="text-h5 ml-7 mt-7 mb-5">Access</div>
                    <v-row class="ml-4 mr-4">
                        <v-col>
                            <groups-selector v-if="loaded" v-model="groups" />
                        </v-col>
                        <v-col>
                            <users-selector v-if="loaded" v-model="users" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="ml-4 mr-4">
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :to="{ name: 'person.contacts' }" class="mr-5 mb-3">
                        Cancel
                    </v-btn>
                    <v-btn color="accent" @click="editPersonContact()" class="mr-3 mb-3">
                        Save
                    </v-btn>
                </v-card-actions>
                <empty-fields-warning
                    button-text="Save"
                    :button-callback="editPersonContact"
                    v-model="showEmptyFieldsWarning"
                />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import router from '@/router';
import ContactsApi from '@/api/Contacts/ContactsApi';
import GroupsSelector from '@/components/Selectors/GroupsSelector';
import UsersSelector from '@/components/Selectors/UsersSelector';
import DateInputField from '@/components/Forms/DateInputField';
import EmptyFieldsWarning from '@/components/Contacts/EmptyFieldsWarning';
import { formatPropertyName } from '@/utils/PersonContactPropertyNameFormatter';

export default {
    components: { EmptyFieldsWarning, DateInputField, UsersSelector, GroupsSelector },
    data() {
        return {
            loaded: false,
            valid: false,
            personContact: {
                initials: '',
                full_name: '',
                first_name: '',
                last_name: '',
                city: '',
                street: '',
                house_number: '',
                postal_code: '',
                bsn: '',
                place_of_birth: '',
                date_of_birth: '',
                nationality: '',
                identification_type: '',
                document_number: '',
                place_of_issue_id: '',
                date_of_issue_id: '',
                date_of_validity_id: '',
                position: '',
            },
            users: [],
            groups: [],
            showEmptyFieldsWarning: false,
            personContactId: '',
            formatPropertyName: formatPropertyName
        };
    },

    async created() {
        const contactsApi = new ContactsApi();
        this.personContactId = this.$route.params.id;
        const responseData = await contactsApi.getSpecificPersonContact(this.personContactId);
        this.personContact = {
            initials: responseData.initials,
            full_name: responseData.full_name,
            first_name: responseData.first_name,
            last_name: responseData.last_name,
            city: responseData.city,
            street: responseData.street,
            house_number: responseData.house_number,
            postal_code: responseData.postal_code,
            bsn: responseData.bsn,
            place_of_birth: responseData.place_of_birth,
            date_of_birth: responseData.date_of_birth,
            nationality: responseData.nationality,
            identification_type: responseData.identification_type,
            document_number: responseData.document_number,
            place_of_issue_id: responseData.place_of_issue_id,
            date_of_issue_id: responseData.date_of_issue_id,
            date_of_validity_id: responseData.date_of_validity_id,
            position: responseData.position,
        };
        this.users = responseData.users;
        this.groups = responseData.groups;
        this.loaded = true;
    },

    methods: {
        async editPersonContact() {
            if (this.hasEmptyFields() && !this.showEmptyFieldsWarning) {
                this.showEmptyFieldsWarning = true;
                return;
            }

            const personContactRequestData = this.personContact;
            personContactRequestData['users'] = this.users;
            personContactRequestData['groups'] = this.groups;

            const contactsApi = new ContactsApi();
            const succeeded = await contactsApi.editPersonContact(
                personContactRequestData,
                this.personContactId
            );

            if (succeeded) {
                await router.push({ name: 'person.contacts' });
            }
        },

        hasEmptyFields() {
            for (const field in this.personContact) {
                if (this.personContact[field] === '') {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
