import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                // primary: '#262626',
                // accent: '#D65132',
                primary: '#412F99',
                secondary: '#A3A4A4',
                accent: '#FFA44F',
            },
        },
        options: {
            cspNonce: 'Zm9jdXNwbGF0Zm9ybXM=',
        },
    },
});
