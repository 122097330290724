import Contacts from '@/views/Contacts/Contacts';
import PersonContactsOverview from '@/views/Contacts/Persons/PersonContactsOverview';
import CompanyContactsOverview from '@/views/Contacts/Companies/CompanyContactsOverview';
import EditPersonContact from '@/views/Contacts/Persons/EditPersonContact';
import NewPersonContact from '@/views/Contacts/Persons/NewPersonContact';
import NewCompanyContact from '@/views/Contacts/Companies/NewCompanyContact';
import EditCompanyContact from '@/views/Contacts/Companies/EditCompanyContact';

export default [
    {
        path: '/contacts',
        component: Contacts,
        meta: {
            auth: true,
        },
        children: [
            {
                path: 'persons',
                component: PersonContactsOverview,
                name: 'person.contacts',
                meta: {
                    auth: true,
                    capabilities: ['ReadContacts'],
                    capability_redirect: 'home',
                },
            },
            {
                path: 'companies',
                component: CompanyContactsOverview,
                name: 'company.contacts',
                meta: {
                    auth: true,
                    capabilities: ['ReadContacts'],
                    capability_redirect: 'home',
                },
            },
        ],
    },
    {
        path: '/contacts/persons/new',
        component: NewPersonContact,
        name: 'new.person.contact',
        meta: {
            auth: true,
            capabilities: ['CreateContacts'],
            capability_redirect: 'home',
        },
    },
    {
        path: '/contacts/persons/edit/:id',
        component: EditPersonContact,
        name: 'edit.person.contact',
        meta: {
            auth: true,
            capabilities: ['UpdateContacts'],
            capability_redirect: 'home',
        },
    },
    {
        path: '/contacts/companies/new',
        component: NewCompanyContact,
        name: 'new.company.contact',
        meta: {
            auth: true,
            capabilities: ['CreateContacts'],
            capability_redirect: 'home',
        },
    },
    {
        path: '/contacts/company/edit/:id',
        component: EditCompanyContact,
        name: 'edit.company.contact',
        meta: {
            auth: true,
            capabilities: ['UpdateContacts'],
            capability_redirect: 'home',
        },
    },
];
