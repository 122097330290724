import AccessManagement from '@/views/AccessManagement/Main';
import UserManagement from '@/views/AccessManagement/Users/UsersOverview';
import GroupManagement from '@/views/AccessManagement/Groups/GroupsOverview';
import RoleManagement from '@/views/AccessManagement/Roles/RolesOverview';

export default [
    {
        path: '/access-management',
        component: AccessManagement,
        name: 'accessmanagement',
        redirect: '/access-management/users',
        meta: {
            auth: true,
        },
        children: [
            {
                path: 'users',
                component: UserManagement,
                name: 'usermanagement',
                meta: {
                    auth: true,
                    capabilities: ['ReadUsers'],
                    capability_redirect: 'groupmanagement',
                },
            },
            {
                path: 'groups',
                component: GroupManagement,
                name: 'groupmanagement',
                meta: {
                    auth: true,
                    capabilities: ['ReadGroups'],
                    capability_redirect: 'rolemanagement',
                },
            },
            {
                path: 'roles',
                component: RoleManagement,
                name: 'rolemanagement',
                meta: {
                    auth: true,
                    capabilities: ['ReadRoles'],
                },
            },
        ],
    },
];
