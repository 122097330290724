<template>
    <v-card>
        <v-card-title>
            <span class="headline">Folder Information</span>
        </v-card-title>
        <v-card-text class="pb-0">
            <v-text-field v-model="newFolderName" label="Folder name" clearable></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-btn
                dark
                color="error"
                class="elevation-3 ma-4"
                @click="$emit('delete-folder', folder)"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
                dark
                color="secondary"
                class="elevation-3 ma-4"
                @click="$emit('move-folder', folder)"
            >
                <v-icon>mdi-folder-move</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                dark
                color="accent"
                class="elevation-3 ma-4"
                @click="
                    $emit('update-folder', {
                        id: folder.id,
                        name: newFolderName,
                    })
                "
                >Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        folder: Object,
    },
    watch: {
        folder(newValue) {
            this.newFolderName = newValue.name;
        },
    },
    data: () => ({
        newFolderName: null,
    }),
    created() {
        this.newFolderName = this.folder.name;
    },
};
</script>

<style scoped></style>
