<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ element.name }}</span>
        </v-card-title>
        <v-card-text>
            <div v-if="folderOnlyTreeStructure.length > 0">
                Where do you want to move this
                {{ element.type === 'folder' ? 'folder' : '' }} to? If no folder is selected, it
                will be moved to the top level.
                <v-treeview
                    :items="folderOnlyTreeStructure"
                    item-disabled="locked"
                    item-key="id"
                    hoverable
                    rounded
                    transition
                    open-all
                    activatable
                    :active.sync="folderToMoveTo"
                    class="mt-3"
                >
                    <template v-slot:prepend="{ item, open }">
                        <v-icon
                            v-text="
                                `mdi-${open && item.children.length > 0 ? 'folder-open' : 'folder'}`
                            "
                        ></v-icon>
                    </template>
                    <template v-slot:label="{ item }">
                        <div v-if="item.type === 'folder'">{{ item.name }}</div>
                    </template>
                </v-treeview>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                small
                color="accent"
                dark
                class="mr-4 mb-3"
                @click="moveElement(element, folderToMoveTo[0])"
                >Move</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        treeStructure: Array,
        element: Object,
    },
    data: () => ({
        folderToMoveTo: [null],
    }),
    computed: {
        folderOnlyTreeStructure() {
            return this.getFoldersOnly(this.treeStructure, this.element, false);
        },
    },
    methods: {
        moveElement(element, folderId) {
            this.folderToMoveTo = [null];
            if (folderId === undefined) {
                this.$emit('move-element', element, null);
            } else {
                this.$emit('move-element', element, folderId);
            }
        },
        getFoldersOnly(root, elementToMove, locked) {
            const folders = [];
            for (let i = 0; i < root.length; i++) {
                const item = root[i];
                let newLock = locked;
                if (item === elementToMove) {
                    newLock = true;
                }
                if (item.type === 'folder') {
                    folders.push({
                        type: 'folder',
                        id: item.id,
                        name: item.name,
                        locked: newLock,
                        children: this.getFoldersOnly(item.children, elementToMove, newLock),
                    });
                }
            }
            return folders;
        },
    },
};
</script>

<style scoped></style>
