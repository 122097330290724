import ApiConsumer from '../ApiConsumer';

export default class TemplateManagement extends ApiConsumer {
    /**
     * Retrieves a specific template
     * @param templateid uuid of the template
     */
    async getSpecificTemplate(templateid) {
        try {
            const response = await this.api.get('/templates/' + templateid + '/');
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Retrieves a specific template
     * @param templateid uuid of the template
     */
    async getSpecificTemplateMetaData(templateid) {
        try {
            const response = await this.api.get('/templates/' + templateid + '/meta-data/');
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Retrieves all templates with folder structure
     */
    async getTemplatesWithFolderStructure() {
        try {
            const response = await this.api.get('/template-folders/');
            return response.data;
        } catch (error) {
            return [];
        }
    }

    /**
     * Edits a template
     */
    async editTemplate(editedTemplate, id) {
        try {
            const response = await this.api.patch('/templates/' + id + '/', editedTemplate);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Creates a template
     */
    async createTemplate(template) {
        try {
            const response = await this.api.post('/templates/', template);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    async delete(id) {
        try {
            await this.api.delete('/templates/' + id + '/');
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Creates a folder
     * @param name folder name
     * @param parent uuid of parent
     */
    async createFolder(name, parent) {
        try {
            const response = await this.api.post('/template-folders/', {
                name: name,
                parent: parent,
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * edits a folder
     * @param id folder id
     * @param editedFolder edited attributes of the folder
     */
    async editFolder(id, editedFolder) {
        try {
            const response = await this.api.patch('/template-folders/' + id + '/', editedFolder);
            return response.data;
        } catch (error) {
            return false;
        }
    }

    /**
     * Deletes a folder
     * @param folderid uuid of folder
     */
    async deleteFolder(folderid) {
        try {
            const response = await this.api.delete('/template-folders/' + folderid + '/');
            return response.data;
        } catch (error) {
            return false;
        }
    }
}
