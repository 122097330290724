<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="account-information elevation-5" min-width="400px">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Account information</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <Errors />
                            <v-form ref="form" v-model="valid" lazy-validation class="ma-5">
                                <v-text-field
                                    v-model="firstName"
                                    label="First name"
                                    :rules="nameRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model="lastName"
                                    label="Last name"
                                    :rules="nameRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model="email"
                                    label="E-mail"
                                    :rules="emailRules"
                                ></v-text-field>
                                <v-text-field
                                    label="Current password"
                                    v-model="currentPassword"
                                    type="password"
                                ></v-text-field>
                                <v-text-field
                                    label="New password"
                                    v-model="newPassword"
                                    type="password"
                                    :rules="passwordRules"
                                ></v-text-field>
                                <v-text-field
                                    label="Confirm new password"
                                    v-model="confirmNewPassword"
                                    type="password"
                                    :rules="passwordRules"
                                ></v-text-field>
                                <v-list>
                                    <v-list-item two-line class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Role</v-list-item-subtitle>
                                            <div class="mt-1">{{ role.name }}</div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Group(s)</v-list-item-subtitle>
                                            <div v-if="groups.length === 0" class="mt-1">
                                                You are in no groups.
                                            </div>
                                            <div v-else class="mt-1">
                                                {{ groups.map((group) => group.name).join(', ') }}
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" @click="saveAccountInformation" class="mr-3 mb-3"
                        >Save</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapMutations } from 'vuex';
import router from '@/router';
import Auth from '@/api/Auth';
import Errors from '@/components/Errors';

export default {
    data: () => ({
        valid: true,
        firstName: null,
        lastName: null,
        email: null,
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null,
        role: {
            name: null,
        },
        groups: [],

        nameRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
        ],

        emailRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
            (v) =>
                (v &&
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        String(v).toLowerCase()
                    )) ||
                'This must be a valid email address',
        ],

        passwordRules: [
            (v) => v == null || v.length >= 8 || 'This field must be at least 8 characters',
            (v) => v == null || v.length <= 1000 || 'This field must be less than 1000 characters',
        ],
    }),
    methods: {
        ...mapMutations(['addError', 'resetErrors']),
        async saveAccountInformation() {
            this.resetErrors();
            const authApi = new Auth();
            const user = {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
            };
            if (this.newPassword != null && this.newPassword != '') {
                if (this.currentPassword == null || this.currentPassword == '') {
                    this.addError('Fill in your current password');
                    return;
                }
                if (this.confirmNewPassword != this.newPassword) {
                    this.addError("Your passwords don't match");
                    return;
                }
                user['current_password'] = this.currentPassword;
                user['new_password'] = this.newPassword;
            }
            try {
                const newUser = await authApi.editCurrentUser(user);
                this.$store.commit('setLoggedInUser', newUser);
            } catch (e) {
                this.addError(e);
                return;
            }
            await router.push({ name: 'templates' });
        },
    },
    async created() {
        const authApi = new Auth();
        const user = await authApi.getCurrentUser();
        this.firstName = user.first_name;
        this.lastName = user.last_name;
        this.email = user.email;
        this.role = user.role;
        this.groups = user.groups;
    },
    components: {
        Errors,
    },
};
</script>
