import NewUser from '@/views/AccessManagement/Users/NewUser';
import EditUser from '@/views/AccessManagement/Users/EditUser';

export default [
    {
        path: '/usermanagement/newuser',
        name: 'newuser',
        component: NewUser,
        meta: {
            auth: true,
            capabilities: ['CreateUsers'],
        },
    },
    {
        path: '/usermanagement/:userid/edituser',
        name: 'edituser',
        component: EditUser,
        meta: {
            auth: true,
            capabilities: ['UpdateUsers'],
        },
    },
];
