<template>
    <v-dialog v-model="showDialog" max-width="600px">
        <v-card>
            <v-card-title class="pb-8"> Empty fields </v-card-title>
            <v-card-subtitle>
                Are you sure you want to save this contact? Some fields are empty.
            </v-card-subtitle>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="secondary" class="elevation-3 ma-3" @click="showDialog = false"
                    >Cancel
                </v-btn>
                <v-btn color="accent" @click="buttonCallback" class="elevation-3 ma-3">{{
                    buttonText
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'EmptyFieldsWarning',

    props: {
        value: {
            type: Boolean,
            default: false,
        },
        buttonText: {
            type: String,
            required: true,
        },
        buttonCallback: {
            type: Function,
            required: true,
        },
    },
    computed: {
        showDialog: {
            get() {
                return this.value;
            },
            set(showDialog) {
                this.$emit('input', showDialog);
            },
        },
    },
};
</script>

<style scoped></style>
