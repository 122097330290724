var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.element.name))])]),_c('v-card-text',[(_vm.folderOnlyTreeStructure.length > 0)?_c('div',[_vm._v(" Where do you want to move this "+_vm._s(_vm.element.type === 'folder' ? 'folder' : '')+" to? If no folder is selected, it will be moved to the top level. "),_c('v-treeview',{staticClass:"mt-3",attrs:{"items":_vm.folderOnlyTreeStructure,"item-disabled":"locked","item-key":"id","hoverable":"","rounded":"","transition":"","open-all":"","activatable":"","active":_vm.folderToMoveTo},on:{"update:active":function($event){_vm.folderToMoveTo=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-icon',{domProps:{"textContent":_vm._s(
                            ("mdi-" + (open && item.children.length > 0 ? 'folder-open' : 'folder'))
                        )}})]}},{key:"label",fn:function(ref){
                        var item = ref.item;
return [(item.type === 'folder')?_c('div',[_vm._v(_vm._s(item.name))]):_vm._e()]}}],null,false,2766284398)})],1):_vm._e()]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4 mb-3",attrs:{"small":"","color":"accent","dark":""},on:{"click":function($event){return _vm.moveElement(_vm.element, _vm.folderToMoveTo[0])}}},[_vm._v("Move")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }