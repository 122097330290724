<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="new-user-card elevation-5" min-width="400px">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Edit User</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-form ref="form" v-model="valid" lazy-validation class="ml-5 mr-5">
                                <v-text-field
                                    v-model="firstName"
                                    label="First name"
                                    :rules="nameRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model="lastName"
                                    label="Last name"
                                    :rules="nameRules"
                                ></v-text-field>
                                <v-text-field
                                    v-model="userEmail"
                                    label="E-mail"
                                    :rules="emailRules"
                                ></v-text-field>
                                <v-text-field
                                    label="New password (optional)"
                                    v-model="newPassword"
                                    :rules="passwordRules"
                                    type="password"
                                ></v-text-field>
                                <v-select
                                    v-model="chosenRole"
                                    :items="possibleRoles"
                                    :rules="roleRules()"
                                    item-text="name"
                                    item-value="id"
                                    label="Role"
                                    :readonly="editRoleLocked"
                                ></v-select>
                            </v-form>
                        </v-col>
                        <v-col>
                            <v-data-table
                                height="260px"
                                :headers="groupsHeaders"
                                :items="groupsContent"
                                :search="searchGroups"
                                v-model="selectedGroups"
                                item-key="id"
                                disable-pagination
                                hide-default-footer
                                fixed-header
                                show-select
                                class="mr-3"
                                mobile-breakpoint="0"
                            >
                                <template v-slot:top>
                                    <v-text-field
                                        v-model="searchGroups"
                                        append-icon="mdi-magnify"
                                        label="Search group"
                                        single-line
                                        hide-details
                                        class="mb-5"
                                    ></v-text-field>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :to="{ name: 'usermanagement' }" class="mr-5 mb-3"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="accent"
                        @click="saveEditedUser()"
                        class="mr-3 mb-3"
                        :disabled="!valid"
                        >Save</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import router from '@/router';
import UserManagement from '@/api/AccessManagement/UserManagement';
import RoleManagement from '@/api/AccessManagement/RoleManagement';
import GroupManagement from '@/api/AccessManagement/GroupManagement';

export default {
    data: () => ({
        valid: false,
        firstName: null,
        lastName: null,
        userEmail: null,
        newPassword: null,
        searchGroups: null,
        groupsHeaders: [
            { text: 'Group', sortable: false, value: 'name' },
            { text: 'Users', sortable: false, value: 'users' },
        ],

        nameRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
        ],

        emailRules: [
            (v) => !!v || 'This field is required',
            (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
            (v) =>
                (v &&
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        String(v).toLowerCase()
                    )) ||
                'This must be a valid email address',
        ],

        passwordRules: [
            (v) => v == null || v.length >= 8 || 'This field must be at least 8 characters',
            (v) => v == null || v.length <= 1000 || 'This field must be less than 1000 characters',
        ],

        groupsContent: [],
        selectedGroups: [],
        chosenRole: null,
        possibleRoles: [],
        editRoleLocked: false,
    }),
    methods: {
        async saveEditedUser() {
            await this.$refs.form.validate();
            if (!this.valid) return;
            const userid = this.$route.params.userid;
            const user = {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.userEmail,
                groups: this.selectedGroups.map((group) => group.id),
                role: this.chosenRole,
            };
            if (this.newPassword !== null) {
                user['new_password'] = this.newPassword;
            }
            const userManagement = new UserManagement();
            await userManagement.editUser(user, userid);
            await router.push({ name: 'usermanagement' });
        },

        roleRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) =>
                    (v && this.possibleRoles.map((r) => r.id).includes(v)) || 'Select a valid role',
            ];
        },
    },
    async beforeMount() {
        const userid = this.$route.params.userid;
        const userManagement = new UserManagement();
        const user = await userManagement.getSpecificUser(userid);
        this.firstName = user.first_name;
        this.lastName = user.last_name;
        this.userEmail = user.email;

        const roleManagement = new RoleManagement();
        this.possibleRoles = await roleManagement.getRoles();
        this.chosenRole = user.role != null ? user.role.id : null;
        const roleName = user.role.name;

        //If the current user is an admin...
        if (roleName === 'Admin') {
            for (const role of this.possibleRoles) {
                if (role.name === 'Admin') {
                    //...and it is the only admin, its role cannot be changed.
                    if (role.users.length < 2) {
                        this.editRoleLocked = true;
                        break;
                    }
                }
            }
        }

        const groupManagement = new GroupManagement();
        this.groupsContent = await groupManagement.getGroups();
        this.selectedGroups = user.groups;
    },
};
</script>
