import { find } from 'lodash';

export const retrieveAllTemplateElements = (root, includeContactElements, includeValue) => {
    let elements = [];
    for (const element of root) {
        if (element.type === 'optional_section') {
            const elementData = {
                id: element.id,
                type: element.type,
                name: element.name,
                label: element.label,
                default: element.default,
            };
            if (includeValue) elementData['value'] = element.default;
            elements.push(elementData);
            elements = elements.concat(
                retrieveAllTemplateElements(element.children, includeContactElements, includeValue)
            );
        } else if (
            element.type === 'currency' ||
            element.type === 'text' ||
            element.type === 'date' ||
            element.type === 'decimal_number' ||
            element.type === 'number' ||
            element.type === 'choice_list' ||
            ((element.type === 'person_contact' || element.type === 'company_contact') &&
                includeContactElements)
        ) {
            const elementData = {
                id: element.id,
                type: element.type,
                name: element.name,
                label: element.label,
                meta_data: { ...element.meta_data },
            };
            if (includeValue) {
                if (element.type === 'currency') {
                    elementData['value'] = {
                        currency_format: element.meta_data.currency_format,
                        amount: null,
                    };
                } else {
                    elementData['value'] = null;
                }
            }
            elements.push(elementData);
        }
    }
    return elements;
};

const getTemplatElementLabelsByType = (root, elementType) => {
    let elements = [];
    for (const element of root) {
        if (element.type === 'optional_section') {
            elements = elements.concat(
                getTemplatElementLabelsByType(element.children, elementType)
            );
        } else if (element.type === elementType) {
            elements.push(element.label);
        }
    }
    return elements;
};

export const getPersonContactTemplateElementLabels = (root) => {
    return getTemplatElementLabelsByType(root, 'person_contact');
};

export const getCompanyContactTemplateElementLabels = (root) => {
    return getTemplatElementLabelsByType(root, 'company_contact');
};

export const mergeOldAndNewTemplateElements = (oldTemplateElements, newTemplateElements) => {
    const mergedTemplateElements = [];

    for (const templateElement of newTemplateElements) {
        const matchingOldTemplateElement = find(oldTemplateElements, {
            name: templateElement.name,
        });

        if (matchingOldTemplateElement) {
            templateElement.type = matchingOldTemplateElement.type;
            templateElement.label = matchingOldTemplateElement.label;
            if (matchingOldTemplateElement.type === 'optional_section') {
                templateElement.default = matchingOldTemplateElement.default;
            } else {
                templateElement.meta_data = matchingOldTemplateElement.meta_data;
            }
        }

        mergedTemplateElements.push(templateElement);
    }

    return mergedTemplateElements;
};
