<template>
    <v-row class="align-self-start">
        <v-col>
            <v-data-table
                height="300px"
                :headers="rolesHeaders"
                :items="rolesContent"
                :search="searchRoles"
                disable-pagination
                hide-default-footer
                fixed-header
                mobile-breakpoint="0"
            >
                <template v-slot:top>
                    <div
                        class="ma-4"
                        v-bind:class="{
                            searchbar: userCan('CreateRoles'),
                            'searchbar-no-create': !userCan('CreateRoles'),
                        }"
                    >
                        <v-text-field
                            v-model="searchRoles"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-btn
                            class="mt-3"
                            :to="{ name: 'newrole' }"
                            dark
                            width="150px"
                            color="accent"
                            v-if="userCan('CreateRoles')"
                        >
                            New Role
                        </v-btn>
                    </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editRole(item)"
                        v-if="item.name !== 'Admin' && userCan('UpdateRoles')"
                        >mdi-pencil</v-icon
                    >
                    <v-icon
                        small
                        @click="deleteRole(item)"
                        v-if="item.name !== 'Admin' && userCan('DeleteRoles')"
                        >mdi-delete</v-icon
                    >
                </template>
            </v-data-table>

            <v-dialog v-model="popup.active" max-width="600px" v-if="userCan('DeleteRoles')">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ popup.title }}</span>
                    </v-card-title>
                    <v-card-text class="pb-0">{{ popup.text }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            color="secondary"
                            class="elevation-3 ma-4"
                            @click="popup.active = false"
                            >Cancel</v-btn
                        >
                        <v-btn color="error" class="elevation-3 ma-4" @click="confirm()"
                            >Delete</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!--The snackbar below is shown when the user tries to delete a role which is attached to other users-->
            <v-snackbar
                color="error"
                v-model="showDeleteRoleErrorMessage"
                :timeout="3000"
                bottom
                transition="fade-transition"
                >You can't delete a role to which users are attached
            </v-snackbar>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import RoleManagement from '@/api/AccessManagement/RoleManagement';
import router from '@/router';

export default {
    data() {
        return {
            panel: [],
            popup: {
                active: false,
                title: null,
                text: null,
                role: null,
            },
            searchRoles: '',
            rolesHeaders: [
                { text: 'Role Name', sortable: false, value: 'name', width: '50%' },
                { text: 'Users', sortable: false, value: 'users' },
                { text: '', value: 'actions', align: 'end', sortable: false },
            ],
            rolesContent: [],
            showDeleteRoleErrorMessage: false,
        };
    },
    methods: {
        async getAllRoles() {
            const roleManagement = new RoleManagement();
            this.rolesContent = await roleManagement.getRoles();
        },
        editRole(role) {
            router.push({
                name: 'editrole',
                params: {
                    roleid: role.id,
                },
            });
        },
        deleteRole(role) {
            if (role.users === 0) {
                this.popup.active = true;
                this.popup.title = 'Delete ' + role.name;
                this.popup.text = 'Are you sure you want to delete this role?';
                this.popup.role = role;
            } else {
                this.showDeleteRoleErrorMessage = true;
            }
        },
        async confirm() {
            const roleManagement = new RoleManagement();
            await roleManagement.deleteRole(this.popup.role.id);
            this.popup.active = false;
            await this.getAllRoles();
        },
    },
    computed: {
        ...mapGetters(['userCan']),
    },
    created() {
        this.getAllRoles();
    },
};
</script>

<style>
.searchbar {
    display: grid;
    grid-template-columns: minmax(200px, 9fr) 1fr;
    grid-column-gap: 35px;
}

.searchbar-no-create {
    display: grid;
    grid-template-columns: minmax(200px, 1fr);
    grid-column-gap: 35px;
}
</style>
