import Vue from 'vue';
import Vuex from 'vuex';
import Auth from '../api/Auth';
import { setStoreForApiHandler } from '@/api/ApiHandler';
import router from '../router';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        accessToken: false,
        refreshToken: false,
        user: null,
        isAuthenticating: false,
        errors: [],
    },
    getters: {
        accessToken: (state) => state.accessToken,
        refreshToken: (state) => state.refreshToken,
        user: (state) => state.user,
        isSystemAdministrator: (state) => state.user != null && state.user.is_superuser,
        userCan: (state, getters) => (permission) =>
            getters.user != null &&
            state.user.role != null &&
            state.user.role.permissions.map((perm) => perm.name).includes(permission),
        isAuthenticated: (state) => state.accessToken !== false && state.refreshToken !== false,
        isAuthenticating: (state) => state.isAuthenticating,
        hasErrors: (state) => state.errors.length > 0,
        errors: (state) => state.errors,
    },
    mutations: {
        setAccessAndRefreshToken(state, { access, refresh }) {
            state.accessToken = access;
            state.refreshToken = refresh;
        },

        setLoggedInUser(state, user) {
            state.user = user;
        },

        setIsAuthenticating(state, isAuthenticating) {
            state.isAuthenticating = isAuthenticating;
        },

        addError(state, msg) {
            state.errors = [...state.errors, msg];
        },

        setErrors(state, msgs) {
            state.errors = msgs;
        },

        resetErrors(state) {
            state.errors = [];
        },
    },
    actions: {
        async heartbeat(store) {
            const AuthApi = new Auth(store);

            if (store.getters.isAuthenticated) {
                await AuthApi.refreshToken(store.getters.refreshToken);
                await store.dispatch('fetchAuth');
            }

            setTimeout(() => {
                store.dispatch('heartbeat');
            }, 60000);
        },

        async fetchAuth(store) {
            const AuthApi = new Auth(store);
            const user = await AuthApi.getCurrentUser();
            store.commit('setLoggedInUser', user);
        },

        async checkAuth(store) {
            store.commit('setIsAuthenticating', true);

            if (
                sessionStorage.getItem('accessToken') !== null &&
                sessionStorage.getItem('refreshToken') !== null
            ) {
                store.commit('setAccessAndRefreshToken', {
                    access: sessionStorage.getItem('accessToken'),
                    refresh: sessionStorage.getItem('refreshToken'),
                });
            }

            if (store.getters.isAuthenticated) {
                const AuthApi = new Auth(store);
                await AuthApi.refreshToken(store.getters.refreshToken);
                await store.dispatch('fetchAuth');
            }

            store.commit('setIsAuthenticating', false);
        },

        async login(store, payload) {
            store.commit('resetErrors');
            store.commit('setIsAuthenticating', true);

            const AuthApi = new Auth(store);

            if (await AuthApi.login(payload.email, payload.password)) {
                router.push({ name: 'home' });
            } else {
                store.commit('addError', 'The username or password you have entered is invalid.');
            }

            store.commit('setIsAuthenticating', false);
        },

        logout() {
            const AuthApi = new Auth(store);
            AuthApi.logout();
            router.push({ name: 'login' });
        },
    },
    modules: {},
});

setStoreForApiHandler(store);

export default store;
