<template>
    <v-row class="align-self-start">
        <v-col>
            <v-card class="new-group-card elevation-5" min-width="400px">
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>New Group</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-text-field
                            class="ml-4 mr-4"
                            v-model="newGroupName"
                            label="Group name"
                            :rules="groupRules"
                        ></v-text-field>
                    </v-form>
                    <v-data-table
                        height="350px"
                        :headers="usersHeaders"
                        :items="usersContent"
                        :search="searchUsers"
                        v-model="selectedUsers"
                        item-key="id"
                        disable-pagination
                        hide-default-footer
                        fixed-header
                        show-select
                        class="ml-4 mr-4"
                        mobile-breakpoint="0"
                    >
                        <template v-slot:top>
                            <v-text-field
                                v-model="searchUsers"
                                append-icon="mdi-magnify"
                                label="Search users"
                                class="mb-3"
                            ></v-text-field>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :to="{ name: 'groupmanagement' }" class="mr-5 mb-3"
                        >Cancel</v-btn
                    >
                    <v-btn color="accent" @click="createNewGroup()" class="mr-3 mb-3">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import router from '@/router';
import UserManagement from '@/api/AccessManagement/UserManagement';
import GroupManagement from '@/api/AccessManagement/GroupManagement';

export default {
    data: () => ({
        valid: false,
        newGroupName: null,
        searchUsers: '',
        usersHeaders: [
            { text: 'Name', value: 'name' },
            { text: 'Email', value: 'email', align: ' d-none d-sm-table-cell' },
            { text: 'Role', value: 'role_name', align: ' d-none d-sm-table-cell' },
        ],
        usersContent: [],
        selectedUsers: [],
        groupNames: [],
    }),
    computed: {
        groupRules() {
            return [
                (v) => !!v || 'This field is required',
                (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
                (v) =>
                    (v && !this.groupNames.includes(v.trim())) || 'This group name already exists',
            ];
        },
    },
    methods: {
        async createNewGroup() {
            await this.$refs.form.validate();
            if (!this.valid) return;

            const groupManagement = await new GroupManagement();
            await groupManagement.createGroup({
                name: this.newGroupName,
                templates: [],
                users: this.selectedUsers.map((user) => user.id),
            });
            await router.push({ name: 'groupmanagement' });
        },
    },
    async created() {
        //Retrieve the names of all roles
        const groupManagement = await new GroupManagement();
        const allGroups = await groupManagement.getGroups();
        const names = [];
        for (const group of allGroups) names.push(group.name);
        this.groupNames = names;

        this.usersContent = await new UserManagement().getUsers();
    },
};
</script>
