import TemplateRegistry from '@/views/TemplatesModule/Templates/TemplateRegistry';
import NewTemplate from '@/views/TemplatesModule/Templates/NewTemplate';
import EditTemplate from '@/views/TemplatesModule/Templates/EditTemplate';

export default [
    {
        path: '/templates',
        name: 'templates',
        component: TemplateRegistry,
        meta: {
            auth: true,
            capabilities: ['ReadTemplates'],
        },
    },
    {
        path: '/templates/:templateid/edittemplate',
        name: 'edittemplate',
        component: EditTemplate,
        meta: {
            auth: true,
            capabilities: ['UpdateTemplates'],
        },
    },
    {
        path: '/templates/newtemplate',
        name: 'newtemplate',
        component: NewTemplate,
        meta: {
            auth: true,
            capabilities: ['CreateTemplates'],
        },
    },
];
