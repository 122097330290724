<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">Document: {{ document.name }}</span>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item two-line class="pa-0">
                        <v-list-item-content>
                            <v-list-item-subtitle>Code</v-list-item-subtitle>
                            <div class="mt-1">{{ document.code }}</div>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line class="pa-0">
                        <v-list-item-content>
                            <v-list-item-subtitle>Created</v-list-item-subtitle>
                            <div class="mt-1">
                                by {{ document.creator_name }} on {{ createdAt }}
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    dark
                    color="error"
                    class="elevation-3 ma-3"
                    @click="confirmDialog = true"
                    v-if="userCan('DeleteDocuments')"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                    dark
                    color="secondary"
                    class="elevation-3 ma-3"
                    @click="editDocument(document)"
                    v-if="userCan('UpdateDocuments')"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    dark
                    color="secondary"
                    class="elevation-3 ma-3"
                    @click="$emit('move-document', document)"
                    v-if="userCan('UpdateDocuments')"
                >
                    <v-icon>mdi-folder-move</v-icon>
                </v-btn>
                <v-spacer class="d-none d-sm-flex"></v-spacer>
                <v-btn
                    dark
                    color="accent"
                    class="elevation-3 ma-3"
                    @click="downloadDocument(document, 1)"
                >
                    <v-icon class="mr-1">mdi-download</v-icon>
                    PDF
                </v-btn>
                <v-btn
                    dark
                    color="accent"
                    class="elevation-3 ma-3"
                    @click="downloadDocument(document, 2)"
                >
                    <v-icon class="mr-1">mdi-download</v-icon>
                    Word
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--The dialog below opens when on the user has to confirm if he wants to delete a document-->
        <v-dialog v-model="confirmDialog" max-width="600px" v-if="userCan('DeleteTemplates')">
            <v-card>
                <v-card-title
                    ><span class="headline">Delete "{{ document.name }}"</span></v-card-title
                >
                <v-card-text class="pb-0">
                    Are you sure you want to delete this document?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        dark
                        class="elevation-3 ma-4"
                        @click="confirmDialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn color="error" class="elevation-3 ma-4" @click="$emit('delete', document)"
                        >Delete</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import router from '@/router';
import DocumentManagement from '@/api/TemplatesModule/DocumentManagement';

export default {
    props: {
        document: Object,
    },
    data: () => ({
        confirmDialog: false,
    }),
    methods: {
        editDocument(document) {
            router.push({
                name: 'editdocument',
                params: { documentid: document.id },
            });
        },
        /**
         * This method is called once the user presses the download button
         * from the document information dialog. From here the user can download
         * the document. Document type can be 1 (pdf) or 2 (word).
         */
        async downloadDocument(targetDocument, documentType) {
            const documentManagement = new DocumentManagement();
            const pdf = documentType === 1;
            await documentManagement.getRenderedDocument(targetDocument, pdf);
        },
    },
    computed: {
        ...mapGetters(['userCan']),
        createdAt() {
            const date = moment(this.document.created_at);
            return date.format('Do [of] MMMM YYYY [at] HH:mm');
        },
    },
};
</script>
