export const labelRules = [
    (v) => !!v || 'This field is required',
    (v) => (v && v.trim().length > 0) || 'This field may not be blank',
    (v) => (v && v.length <= 1000) || 'This field must be less than 1000 characters',
];

export const numberRules = [
    (v) => v === null || v === '' || Number.isFinite(Number(v)) || 'This field must be numeric',
];

export const requiredRules = [(v) => !!v || 'This field is required'];

export const selectorRules = [(v) => !!v || 'This field is required'];

export const currencyRules = [(v) => !!v || 'This field is required'];

export const optionRules = [(v) => !!v || 'This field is required'];

export const dateRules = [
    (v) => !!v || 'This field is required',
    (v) => !isNaN(Date.parse(v)) || 'This date is invalid',
];

export const validationRulesDecimalNumberPlaceHolder = (placeholder) => {
    return [
        (v) => !!v || 'This field is required',
        (v) =>
            (v && (placeholder.meta_data.minimum === null || v >= placeholder.meta_data.minimum)) ||
            placeholder.label + ' must be more than or equal to ' + placeholder.meta_data.minimum,
        (v) =>
            (v && (placeholder.meta_data.maximum === null || v <= placeholder.meta_data.maximum)) ||
            placeholder.label + ' must be less than or equal to ' + placeholder.meta_data.maximum,
    ];
};

export const validationRulesNumberPlaceHolder = (placeholder) => {
    return [
        (v) => !!v || 'This field is required',
        (v) => (v && v % 1 === 0) || placeholder.label + ' must be a round number',
        (v) =>
            (v && (placeholder.meta_data.minimum === null || v >= placeholder.meta_data.minimum)) ||
            placeholder.label + ' must be more than or equal to ' + placeholder.meta_data.minimum,
        (v) =>
            (v && (placeholder.meta_data.maximum === null || v <= placeholder.meta_data.maximum)) ||
            placeholder.label + ' must be less than or equal to ' + placeholder.meta_data.maximum,
    ];
};

export const validationChoiceListPlaceHolder = (placeholder) => {
    return [
        (v) => !!v || 'This field is required',
        (v) =>
            (v && placeholder.meta_data.choices.includes(v)) ||
            placeholder.label + ' must be chosen from the list',
    ];
};
