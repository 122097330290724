<template>
    <v-row class="align-self-start">
        <v-col>
            <v-data-table
                height="500px"
                :headers="personContactsHeaders"
                :items="personContactsItems"
                :search="searchPersonContactsQuery"
                disable-pagination
                hide-default-footer
                fixed-header
                mobile-breakpoint="0"
            >
                <template v-slot:top>
                    <div
                        class="ma-4"
                        :class="{
                            searchbar: userCan('CreateContacts'),
                            'searchbar-no-create': !userCan('CreateContacts'),
                        }"
                    >
                        <v-text-field
                            v-model="searchPersonContactsQuery"
                            append-icon="mdi-magnify"
                            hide-details
                            label="Search"
                            single-line
                        ></v-text-field>
                        <v-btn
                            v-if="userCan('CreateContacts')"
                            :to="{ name: 'new.person.contact' }"
                            class="mt-3"
                            color="accent"
                            dark
                            width="275px"
                        >
                            New Person Contact
                        </v-btn>
                    </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        v-if="userCan('UpdateContacts')"
                        class="mr-2"
                        @click="editPersonContact(item)"
                        small
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        v-if="userCan('DeleteContacts')"
                        class="mr-2"
                        @click="deletePersonContact(item)"
                        small
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="popup.active" max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ popup.title }}</span>
                    </v-card-title>
                    <v-card-text class="pb-0">{{ popup.text }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            color="secondary"
                            class="elevation-3 ma-4"
                            @click="popup.active = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn dark color="error" class="elevation-3 ma-4" @click="confirmDelete()">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';
import ContactsApi from '@/api/Contacts/ContactsApi';

export default {
    data() {
        return {
            personContactsHeaders: [
                {
                    text: 'Initials',
                    sortable: true,
                    value: 'initials',
                    width: '18%',
                },
                {
                    text: 'First name',
                    sortable: true,
                    value: 'first_name',
                    width: '18%',
                },
                {
                    text: 'Last name',
                    sortable: true,
                    value: 'last_name',
                    width: '18%',
                },
                {
                    text: 'Bsn',
                    sortable: true,
                    value: 'bsn',
                    width: '18%',
                },
                {
                    text: 'Position',
                    sortable: true,
                    value: 'position',
                    width: '18%',
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: true,
                    align: 'end',
                },
            ],
            personContactsItems: [],
            searchPersonContactsQuery: '',
            popup: {
                active: false,
                title: null,
                text: null,
                type: null,
                userid: null,
            },
        };
    },

    computed: { ...mapGetters(['userCan']) },

    methods: {
        editPersonContact(personContact) {
            router.push({
                name: 'edit.person.contact',
                params: {
                    id: personContact.id,
                },
            });
        },
        deletePersonContact(personContact) {
            this.popup.title = `Delete ${personContact.first_name} ${personContact.last_name}`;
            this.popup.text = 'Are you sure you want to delete this person contact?';
            this.popup.personContactId = personContact.id;
            this.popup.active = true;
        },
        async confirmDelete() {
            const contactsApi = new ContactsApi();
            await contactsApi.deletePersonContact(this.popup.personContactId);
            this.personContactsItems = await contactsApi.getPersonContacts();
            this.popup.active = false;
        },
    },

    async created() {
        const contactsApi = new ContactsApi();
        this.personContactsItems = await contactsApi.getPersonContacts();
    },
};
</script>

<style></style>
